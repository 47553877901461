import { NgxMaskModule } from 'ngx-mask';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData, TitleCasePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { HomeComponent } from './views/home/home.component';
import { FullLayoutComponent } from './containers/full-layout/full-layout.component';
import { LoginComponent } from './views/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { ShiftComponent } from './views/shift/shift.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import localePt from '@angular/common/locales/pt';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { ShiftNewComponent } from './views/shift/shift-new/shift-new.component';
import { SearchHospitalComponent } from './components/search-hospital/search-hospital.component';
import { SearchSpecialtyComponent } from './components/search-specialty/search-specialty.component'
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { NgxCurrencyModule } from "ngx-currency";
import { SearchDoctorCompanyComponent } from './components/search-doctor-company/search-doctor-company.component';
import { ShiftDetailsComponent } from './views/shift/shift-details/shift-details.component';
import { CalendarComponent } from './views/calendar/calendar.component';
import { ShiftConfirmComponent } from './views/shift/shift-confirm/shift-confirm.component';
//import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ShiftSearchComponent } from './views/shift/shift-search/shift-search.component';
import { ShiftSearchFilterComponent } from './views/shift/shift-search-filter/shift-search-filter.component';
import { Ng5SliderModule } from 'ng5-slider';
import { SearchCityComponent } from './components/search-city/search-city.component';
import { RegisterComponent } from './views/register/register.component';
import { RegisterVerifyComponent } from './views/register/register-verify/register-verify.component';
import { ProfileComponent } from './views/profile/profile.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProfilePersonalComponent } from './views/profile/profile-personal/profile-personal.component';
import { ProfileSelfieComponent } from './views/profile/profile-selfie/profile-selfie.component';
import { CameraComponent } from './components/camera/camera.component';
import { ProfileDocumentComponent } from './views/profile/profile-document/profile-document.component';
import { SafePipe } from './helpers/safe.pipe';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { DoctorComponent } from './views/doctor/doctor.component';
import { DoctorCompanyComponent } from './views/doctor/doctor-company/doctor-company.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { DoctorBookChatComponent } from './views/doctor-book/doctor-book-chat/doctor-book-chat.component';
import { DoctorBookDiagnosticComponent } from './views/doctor-book/doctor-book-diagnostic/doctor-book-diagnostic.component';
import { DoctorBookTreatmentComponent } from './views/doctor-book/doctor-book-treatment/doctor-book-treatment.component';
import { SearchDiseaseComponent } from './components/search-disease/search-disease.component';
import { PatientCalendarComponent } from './views/patient/patient-calendar/patient-calendar.component';
import { PatientComponent } from './views/patient/patient/patient.component';
import { PatientMedicalRecordComponent } from './views/patient/patient-medical-record/patient-medical-record.component';
import { PatientFormComponent } from './views/patient/patient-form/patient-form.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PatientAppointmentComponent } from './views/patient/patient-appointment/patient-appointment.component';
import { TimerComponent } from './components/timer/timer.component';
import { DoctorOpenScheduleComponent } from './components/doctor-open-schedule/doctor-open-schedule.component';
import { DoctorScheduleFormComponent } from './components/doctor-schedule-form/doctor-schedule-form.component';
import { SearchPatientComponent } from './components/search-patient/search-patient.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PatientBookingComponent } from './views/patient/patient-booking/patient-booking.component';
import { RegisterAccountComponent } from './views/register/register-account/register-account.component';
import { ChangeCompanyComponent } from './components/change-company/change-company.component';
import { CompanyComponent } from './views/company/company.component';
import { PaymentComponent } from './views/payment/payment.component';
import { CompanyHospitalFormComponent } from './views/company/company-hospital-form/company-hospital-form.component';
import { CompanyUserFormComponent } from './views/company/company-user-form/company-user-form.component';
import { ProfileChangePasswordComponent } from './views/profile/profile-change-password/profile-change-password.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ForgotPasswordChangeComponent } from './views/forgot-password/forgot-password-change/forgot-password-change.component';

defineLocale('pt-br', ptBrLocale);
registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    FooterComponent,
    TopbarComponent,
    HomeComponent,
    FullLayoutComponent,
    LoginComponent,
    ShiftComponent,
    ShiftNewComponent,
    SearchHospitalComponent,
    SearchSpecialtyComponent,
    SearchDoctorCompanyComponent,
    ShiftDetailsComponent,
    CalendarComponent,
    ShiftConfirmComponent,
    ShiftSearchComponent,
    ShiftSearchFilterComponent,
    SearchCityComponent,
    RegisterComponent,
    RegisterVerifyComponent,
    ProfileComponent,
    ProfilePersonalComponent,
    ProfileSelfieComponent,
    CameraComponent,
    ProfileDocumentComponent,
    SafePipe,
    DoctorComponent,
    DoctorCompanyComponent,
    DoctorBookChatComponent,
    DoctorBookDiagnosticComponent,
    DoctorBookTreatmentComponent,
    SearchDiseaseComponent,
    PatientCalendarComponent,
    PatientComponent,
    PatientMedicalRecordComponent,
    PatientFormComponent,
    PatientAppointmentComponent,
    TimerComponent,
    DoctorOpenScheduleComponent,
    DoctorScheduleFormComponent,
    SearchPatientComponent,
    PatientBookingComponent,
    RegisterAccountComponent,
    ChangeCompanyComponent,
    CompanyComponent,
    PaymentComponent,
    CompanyHospitalFormComponent,
    CompanyUserFormComponent,
    ProfileChangePasswordComponent,
    ForgotPasswordComponent,
    ForgotPasswordChangeComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ModalModule.forRoot(),
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxCurrencyModule,
    //ZXingScannerModule,
    Ng5SliderModule,
    BsDropdownModule.forRoot(),
    CollapseModule,
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    TabsModule.forRoot()
  ],
  providers: [
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    TitleCasePipe,
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
     },
     { provide: Window, useValue: window }
  ],
  entryComponents: [
    ShiftNewComponent,
    ShiftDetailsComponent,
    ShiftSearchFilterComponent,
    ProfilePersonalComponent,
    ProfileSelfieComponent,
    ProfileDocumentComponent,
    PatientFormComponent,
    DoctorOpenScheduleComponent,
    DoctorScheduleFormComponent,
    ChangeCompanyComponent,
    CompanyHospitalFormComponent,
    CompanyUserFormComponent,
    ProfileChangePasswordComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
