import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const url = environment.endpoint;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userAuthenticated = new EventEmitter<boolean>();
  
  constructor(private http: HttpClient) { }

  login(username: string, password: string) {
    return this.http.post<any>(`${environment.endpoint}/login`, 
        { 
          login: username, 
          password: password
        })
        .pipe(map(response => {
            if (response && response.user.token) {
                localStorage.setItem('currentUser', JSON.stringify(response.user));
                this.userAuthenticated.emit(true);
            }

            return response;
        }));
  }

  changeCompany(companyId: any) {
    return this.http.post<any>(`${environment.endpoint}/login/company/` + companyId + `/change`, null)
        .pipe(map(response => {
          this.logout();
            if (response && response.user.token) {
                localStorage.setItem('currentUser', JSON.stringify(response.user));
                this.userAuthenticated.emit(true);
            }

            return response;
        }));
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.userAuthenticated.emit(false);
  }

  getUsers(): Observable<any> {
    return this.http.get<any>(url + "/company/user", httpOptions);
  }

  addUser(request: any): Observable<any> {
    return this.http.post<any>(url + "/user", request, httpOptions);
  }

  getUserToken(token: string): Observable<any> {
    return this.http.get<any>(url + "/user/token/" + token, httpOptions);
  }

  completeRegistration(request: any): Observable<any> {
    return this.http.put<any>(url + "/user/complete", request, httpOptions);
  }

  getUser(): Observable<any> {
    return this.http.get<any>(url + "/user", httpOptions);
  }

  editUser(request: any): Observable<any> {
    return this.http.put<any>(url + "/user", request, httpOptions);
  }

  changePassword(request: any): Observable<any> {
    return this.http.put<any>(url + "/user/change-password", request, httpOptions);
  }

  sendForgotPasswordToken(request: any): Observable<any> {
    return this.http.post<any>(url + "/user/forgot-password", request, httpOptions);
  }

  forgotPassword(request: any): Observable<any> {
    return this.http.put<any>(url + "/user/forgot-password", request, httpOptions);
  }
}
