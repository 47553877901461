import { Component, OnInit, ViewChild } from '@angular/core';
import { calculateAge } from "../../../util/util.js";
import { BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { PatientService } from 'src/app/service/patient.service.js';
import { take } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { DoctorBookService } from 'src/app/service/doctor-book.service.js';
import { ActivatedRoute, Router } from '@angular/router';
import { TabsetComponent } from 'ngx-bootstrap';
import { CanComponentDeactivate } from '../../../guards/can-deactivate.guard';

@Component({
  selector: 'app-patient-appointment',
  templateUrl: './patient-appointment.component.html',
  styleUrls: ['./patient-appointment.component.scss']
})
export class PatientAppointmentComponent implements OnInit, CanComponentDeactivate  {

  title: string = 'Atendimento';
  patient: any = {};
  patientId: number = 0;
  appointment: any;
  diagnosticSymptoms: string;
  basePhrase: string = 'Listar hipótese diagnostica com o CID-10 para paciente que apresenta os seguintes sintomas: ';
  basePhraseTreatment: string = 'Qual o tratamento farmacológico para os casos de  ';
  isFinished: boolean;
  appointments: any[];

  @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;

  constructor(
    private spinner: NgxSpinnerService,
    private doctorBookService: DoctorBookService,
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.patientId = this.route.snapshot.params.id;
    this.getPatient();
    this.getAppointment();
  }

  getPatient(){
    this.spinner.show()
    this.patientService.getById(this.patientId)
    .pipe(take(1)).subscribe(response => {  
      if (response.data){
        this.patient = response.data;
        this.appointments = response.data.appointments;
        if (this.appointments){
          this.appointments = response.data.appointments.sort((a, b) => (a.id < b.id) ? 1 : -1);
        }
      }
      this.spinner.hide();
    });
  }

  getAge(age: string){
    return calculateAge(new Date(age))
  }

  getDisease(value: string){
    this.appointment.diagnostic = value;
    this.updateAppointment();
  }

  finishAppointment(){

    Swal.fire({
      title: 'Finalizar Atendimento',
      icon: 'question',
      html:
        '<p>Deseja realmente <b>finalizar</b> o atendimento?</p>',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonColor: '#1cc88a',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result: { isConfirmed: any; }) => {
      if (result.isConfirmed){
        this.finish();
      }
    });

    
  }

  finish(){
    this.patientService.finishAppointment(this.appointment)
        .pipe(take(1)).subscribe(response => {
          if (response.data){
            this.isFinished = true;    
            this.router.navigate([`/paciente/${this.patientId}/prontuario`]);
          }
          else {
            Swal.fire(
              'Atenção!',
              response.validationErrors[0].errorMessage,
              'warning'
            )
          } 
        }, () => {
          Swal.fire(
            'Ops!',
            'Ocorreu um erro ao buscar o atendimento.',
            'warning'
          )
        });
  }

  updateAppointment(){
    this.patientService.updateAppointment(this.appointment)
        .pipe(take(1)).subscribe(() => { 
          console.log('Atendimento Atualizado')
        }, () => {
          Swal.fire(
            'Ops!',
            'Ocorreu um erro ao tentar iniciar o atendimento.',
            'warning'
          )
        });
  }

  getAppointment(){
    this.patientService.getCurrentAppointment(this.patientId)
        .pipe(take(1)).subscribe(response => {
          if (response.data){
            this.appointment = response.data;
          }
          
        }, () => {
          Swal.fire(
            'Ops!',
            'Ocorreu um erro ao buscar o atendimento.',
            'warning'
          )
        });
  }

  getdiagnosticHypothesis(){
    Swal.fire({
      title: 'Atenção!',
      icon: 'warning',
      html:
        '<p>É importante ressaltar que essas são apenas hipóteses com base nos sintomas mencionados e não substituem uma avaliação médica adequada.</p>',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonColor: '#36b9cc',
      confirmButtonText: 'Pesquisar',
      cancelButtonText: 'Não'
    }).then((result: { isConfirmed: any; }) => {
      if (result.isConfirmed){
        this.spinner.show();
        var args = this.basePhrase;
        //Sintomas
        args = args + this.appointment.symptoms;

        this.doctorBookService.getDiagnostic(args)
        .pipe(take(1)).subscribe(response => {  
          if (response.data){
            this.staticTabs.tabs[2].active = true;
            this.appointment.diagnosticHypothesis = response.data;
            this.updateAppointment();
          }
          else {
            Swal.fire(
              'Atenção!',
              'Ocorreu um erro ao pesquisar sua resposta.',
              'warning'
            );
          }
          this.spinner.hide();
        }, err => {
            Swal.fire(
              'Ops!',
              'Ocorreu um erro ao pesquisar sua resposta.',
              'error'
            );
        });
        
      }
    });
  }

  getTreatment(){
    Swal.fire({
      title: 'Atenção!',
      icon: 'warning',
      html:
        '<p>É importante ressaltar que essas são apenas hipóteses com base nos sintomas mencionados e não substituem uma avaliação médica adequada.</p>',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonColor: '#36b9cc',
      confirmButtonText: 'Pesquisar',
      cancelButtonText: 'Não'
    }).then((result: { isConfirmed: any; }) => {
      if (result.isConfirmed){
        this.spinner.show();
        var args = this.basePhraseTreatment;
        //Doença
        args = args + this.appointment.diagnostic;

        this.doctorBookService.getDiagnostic(args)
        .pipe(take(1)).subscribe(response => {  
          if (response.data){
            this.staticTabs.tabs[2].active = true;
            this.appointment.treatment = response.data;
            this.updateAppointment();
          }
          else {
            Swal.fire(
              'Atenção!',
              'Ocorreu um erro ao pesquisar sua resposta.',
              'warning'
            );
          }
          this.spinner.hide();
        }, err => {
            Swal.fire(
              'Ops!',
              'Ocorreu um erro ao pesquisar sua resposta.',
              'error'
            );
        });
        
      }
    });
  }

  canDeactivate(): Promise<boolean> {

    return new Promise<boolean>((resolve) => {
      if (this.isFinished){
        resolve(true);
        return;
      }

      Swal.fire({
        title: 'Finalizar Atendimento?',
        text: 'Antes de sair, é necessário primeiro finalizar o atendimento.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, Finalizar!',
        cancelButtonText: 'Fechar',
      }).then((result) => {
        if (result.isConfirmed) {
          resolve(true); // User confirmed, allow navigation
          this.finish();
        } else {
          resolve(false); // User chose to stay, prevent navigation
        }
      });
    });
  }

}
