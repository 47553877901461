import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsDatepickerConfig, BsLocaleService, BsModalRef, listLocales } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import { ScheduleService } from 'src/app/service/schedule.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-doctor-schedule-form',
  templateUrl: './doctor-schedule-form.component.html',
  styleUrls: ['./doctor-schedule-form.component.scss']
})
export class DoctorScheduleFormComponent implements OnInit {

  locale = 'pt-BR';  
  locales = listLocales();
  bsConfig: Partial<BsDatepickerConfig>;
  
  title: string;
  event: any;

  scheduleType: string = 'schedule'

  patient_id: number = 0;
  patient_name: string;
  patient_cpf: string;
  patient_telephone: string;
  patient_email: string;


  constructor(
    public bsModalRef: BsModalRef,
    private localeService: BsLocaleService,
    private scheduleService : ScheduleService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  ngOnInit() {
    this.bsConfig = Object.assign({}, { 
      containerClass: 'theme-blue',
      placement: 'top'
    });
    this.localeService.use(this.locale);

    if(this.event.meta.event.patient){
      this.patient_id = this.event.meta.event.patient.id;
    }
  }

  send(){
    if (this.scheduleType == 'schedule')
      this.schedulePatient();
    else
      this.blockSchedule();
  }

  schedulePatient(){
    
    this.spinner.show();

    var request = {
      scheduleId: this.event.meta.event.id,
      patientId: this.patient_id,
      name: this.patient_name,
      cpf: this.patient_cpf,
      email: this.patient_email,
      telephone: this.patient_telephone
    }


    this.scheduleService.schedulePatient(request)
    .pipe(take(1))
    .subscribe(response => {

      this.spinner.hide();   
      if(response.data){
        this.bsModalRef.hide();
        Swal.fire(
          'Parabéns!',
          'Paciente agendado com sucesso.',
          'success'
        );
      }
      else{
        Swal.fire(
          'Atenção!',
          response.validationErrors[0].errorMessage,
          'warning'
        );
      } 
    }, () => {
      this.spinner.hide();
      Swal.fire(
        'Ops!',
        'Ocorreu um erro ao agendar o paciente.',
        'error'
      );
    });
  }

  blockSchedule(){
    
    this.spinner.show();

    this.scheduleService.blockSchedule(this.event.meta.event.id)
    .pipe(take(1))
    .subscribe(response => {

      this.spinner.hide();   
      if(response.data){
        this.bsModalRef.hide();
        Swal.fire(
          'Parabéns!',
          'Horário bloqueado com sucesso.',
          'success'
        );
      }
      else{
        Swal.fire(
          'Atenção!',
          response.validationErrors[0].errorMessage,
          'warning'
        );
      } 
    }, () => {
      this.spinner.hide();
      Swal.fire(
        'Ops!',
        'Ocorreu um erro ao bloquear horário.',
        'error'
      );
    });
  }

  verify(){
    if (this.patient_name == '')
      return false;

    if (this.patient_cpf == '')
      return false;

    if (this.patient_telephone == '')
      return false;
    
    return true;
  }

  getPatient(patient: any){

    if (patient == null) {
      this.patient_id = 0;
      this.patient_name = '';
      this.patient_cpf = '';
      this.patient_email = '';
      this.patient_telephone = '';
    }
    else {
      this.patient_id = patient.id;
      this.patient_name = patient.name;
      this.patient_cpf = patient.cpf;
      this.patient_email = patient.email;
      this.patient_telephone = patient.telephone;
    }    
  }

  getPatientName(name: string){
    this.patient_name = name;
  }

  cancelSchedule(){
    Swal.fire({
      title: 'Atenção!',
      icon: 'question',
      html: '<p>Deseja realmente <b>cancelar</b> este agendamento?</p>',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonColor: '#e74a3b',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result: { isConfirmed: any; }) => {
      if (result.isConfirmed){
        this.spinner.show();

        this.scheduleService.reopenSchedule(this.event.meta.event.id)
        .pipe(take(1))
        .subscribe(response => {

          this.spinner.hide();   
          if(response.data){
            this.bsModalRef.hide();
            Swal.fire(
              'Parabéns!',
              'Agendamento cancelado com sucesso.',
              'success'
            );
          }
          else{
            Swal.fire(
              'Atenção!',
              response.validationErrors[0].errorMessage,
              'warning'
            );
          } 
        }, () => {
          this.spinner.hide();
          Swal.fire(
            'Ops!',
            'Ocorreu um erro ao cancelar agendamento.',
            'error'
          );
        });
      }
    });
    
  }

  confirmSchedule(){
    Swal.fire({
      title: 'Atenção!',
      icon: 'question',
      html: '<p>Deseja realmente <b>confirmar</b> este agendamento?</p>',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonColor: '#1cc88a',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result: { isConfirmed: any; }) => {
      if (result.isConfirmed){
        this.spinner.show();

        this.scheduleService.confirmSchedule(this.event.meta.event.id)
        .pipe(take(1))
        .subscribe(response => {

          this.spinner.hide();   
          if(response.data){
            this.bsModalRef.hide();
            Swal.fire(
              'Parabéns!',
              'Agendamento confirmado com sucesso.',
              'success'
            );
          }
          else{
            Swal.fire(
              'Atenção!',
              response.validationErrors[0].errorMessage,
              'warning'
            );
          } 
        }, () => {
          this.spinner.hide();
          Swal.fire(
            'Ops!',
            'Ocorreu um erro ao confirmar agendamento.',
            'error'
          );
        });
      }
    });
  }

  reopenSchedule(){
    Swal.fire({
      title: 'Atenção!',
      icon: 'question',
      html: '<p>Deseja realmente <b>desbloquear</b> este agendamento?</p>',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonColor: '#1cc88a',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result: { isConfirmed: any; }) => {
      if (result.isConfirmed){
        this.spinner.show();

        this.scheduleService.reopenSchedule(this.event.meta.event.id)
        .pipe(take(1))
        .subscribe(response => {

          this.spinner.hide();   
          if(response.data){
            this.bsModalRef.hide();
            Swal.fire(
              'Parabéns!',
              'Agendamento desbloqueado com sucesso.',
              'success'
            );
          }
          else{
            Swal.fire(
              'Atenção!',
              response.validationErrors[0].errorMessage,
              'warning'
            );
          } 
        }, () => {
          this.spinner.hide();
          Swal.fire(
            'Ops!',
            'Ocorreu um erro ao desbloquear agendamento.',
            'error'
          );
        });
      }
    });
    
  }

  goToPatient(){
    this.bsModalRef.hide();
    this.router.navigate([`/paciente/${this.patient_id}/prontuario`]);
  }

}
