import { CommonService } from './../../service/common.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { take, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-search-hospital',
  templateUrl: './search-hospital.component.html',
  styleUrls: ['./search-hospital.component.scss']
})
export class SearchHospitalComponent implements OnInit {

  hospitals: any[] = [];
  hospital: string;
  dataSource: Observable<any>;
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;

  @Input() hospitalSelected: number;
  @Output('hospitalId') hospitalId = new EventEmitter<number>();

  constructor(
    private commonService: CommonService,
    private titlecasePipe:TitleCasePipe
    ) {
    this.dataSource = Observable.create((observer: any) => {
      observer.next(this.hospital);
    })
      .pipe(
        mergeMap((token: string) => this.getHospitalsAsObservable(token))
      );
   }

  ngOnInit() {
    this.getAllHospital();
  }

  getAllHospital(){
    
    this.commonService.getAllHospitals()
    .pipe(take(1)).subscribe(response => {      
      this.hospitals = response.data;
      this.hospitals.filter(item => {
        item.name = this.titlecasePipe.transform(item.name);
      });
      this.setHospitalDefault();
    });
  }

  getHospitalsAsObservable(token: string): Observable<any> {
    const query = new RegExp(token, 'i');
 
    return of(
      this.hospitals.filter((item: any) => {
        return query.test(item.name);
      })
    );
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.hospitalId.emit(e.item.id); 
  }

  onChange(){
    if(this.hospital == "")
      this.hospitalId.emit(0);
  }

  setHospitalDefault(){
    if (this.hospitalSelected != undefined){      
      this.hospitals.filter((item: any) => {
        if (item.id == this.hospitalSelected)
          this.hospital = item.name;
      })
      this.hospitalId.emit(this.hospitalSelected);
    }
  }

}
