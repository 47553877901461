import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy {

  @Input() startDate: string;
  timer: any;
  timeElapsed: string;

  constructor() { }

  ngOnInit() {
    this.startTimer();
  }

  ngOnDestroy() {
    this.stopTimer();
  }

  startTimer() {
    const startTime = new Date(this.startDate).getTime();

    this.timer = setInterval(() => {
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - startTime;

      this.timeElapsed = this.formatTime(timeDifference);
    }, 1000);
  }

  stopTimer() {
    clearInterval(this.timer);
  }

  formatTime(milliseconds: number): string {
    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
    const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

    return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
  }

  padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

}
