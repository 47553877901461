import { shiftStatus } from './../../models/const';
import { ShiftNewComponent } from './shift-new/shift-new.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { addDays, addWeeks, addMonths, subDays, subWeeks, subMonths, startOfDay, startOfWeek, startOfMonth, endOfDay, endOfWeek, endOfMonth, isSameMonth, isSameDay } from 'date-fns';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import { ShiftService } from 'src/app/service/shift.service';
import * as moment from 'moment';
import { CalendarView, CalendarEvent, CalendarDateFormatter } from 'angular-calendar';
import { Observable, Subject } from 'rxjs';
import { ShiftDetailsComponent } from './shift-details/shift-details.component';
import { CustomDateFormatter } from 'src/app/helpers/custom-date-formatter.provider';

type CalendarPeriod = 'day' | 'week' | 'month';


@Component({
  selector: 'app-shift',
  templateUrl: './shift.component.html',
  styleUrls: ['./shift.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    }
  ]
})
export class ShiftComponent implements OnInit {
 
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  minDate: Date = new Date();
  maxDate: Date = new Date();
  locale: string = 'pt';
  activeDayIsOpen: boolean = false;
  events: Array<CalendarEvent<{ shift: any }>> = [];
  refresh: Subject<any> = new Subject();

  startDate: string = null;
  endDate: string = null;
  bsModalRef: BsModalRef;

  constructor(
    private shiftService: ShiftService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.getAllByCompany();
  }

  getAllByCompany(){
    this.activeDayIsOpen = false;

    this.startDate = moment(new Date(this.viewDate)).startOf('month').format('YYYY-MM-DD');
    this.endDate = moment(new Date(this.viewDate)).endOf('month').format('YYYY-MM-DD');
    
    this.shiftService.getAllByCompany(this.startDate, this.endDate)
    .pipe(take(1)).subscribe(response => {  
      if (response.data){
        this.events = response.data.map((shift: any) => {
          return {            
            title:  shift.hospital.name,
            start: new Date(moment(shift.startDate).format()),
            //end: new Date(moment(shift.endDate).format()),
            color: shiftStatus.filter(item => item.id == shift.status.id)[0].color,
            meta: {
              shift
            }
          }
        });
      }
      this.refresh.next();
      this.spinner.hide();
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  openModalShift(): void {
    
    var initialState = {      
    };

    this.bsModalRef = this.modalService.show(
      ShiftNewComponent, Object.assign({}, { 
        class: 'modal-lg',
        initialState: initialState
      }
    ));

    this.modalService.onHidden
    .pipe(
      take(1)
    )
    .subscribe((reason: string) => {      
      this.getAllByCompany();
    })
  }; 

  openShiftDetails(meta: any): void {

    var initialState = {    
      shift: meta.shift
    };

    this.bsModalRef = this.modalService.show(
      ShiftDetailsComponent, Object.assign({}, { 
        class: '',
        initialState: initialState
      }
    ));

    this.modalService.onHidden
    .pipe(
      take(1)
    )
    .subscribe((reason: string) => {      
      this.getAllByCompany();
    });

  }; 

}
