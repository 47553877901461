import { DoctorService } from './../../service/doctor.service';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-doctor',
  templateUrl: './doctor.component.html',
  styleUrls: ['./doctor.component.scss']
})
export class DoctorComponent implements OnInit {

  doctor: any;
  doctorId: number;
  crm: any[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    private doctorService: DoctorService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.doctorId = this.route.snapshot.params.id;
    this.getById();
  }

  getById(){
    this.spinner.show();
    
    this.doctorService.getById(this.doctorId)
    .pipe(take(1)).subscribe(response => {  
      if (response.data){
        this.doctor = response.data;
        console.log(this.doctor)
      }
      this.spinner.hide();
    });
  }

}
