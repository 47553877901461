import { administratorMenu, doctorMenu } from './../../models/const';
import { CommonService } from './../../service/common.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  isToggled: boolean = false;
  constructor(private commonService: CommonService) { }
  menu: any;
  user: any;

  ngOnInit() {
    this.commonService.isSidebarToogled
    .subscribe((result: boolean) => {
      this.isToggled = result;
    });

    this.user = JSON.parse(localStorage.getItem('currentUser'));

    this.menu = this.user.functions.filter(item => {
      if (item.menu == 'sidebar')
        return item;
    });
  }

  toogleSidebar(){
    this.isToggled = !this.isToggled;
    this.commonService.changeResult(this.isToggled);
  } 

}
