import { Component, OnInit } from '@angular/core';
import { validatePassword } from "../../../util/util.js";
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/service/user.service';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password-change',
  templateUrl: './forgot-password-change.component.html',
  styleUrls: ['./forgot-password-change.component.scss']
})
export class ForgotPasswordChangeComponent implements OnInit {

  token: string = '';
  password: string = '';
  confirmPassword: string = '';

  constructor(
    public spinner: NgxSpinnerService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.token = this.route.snapshot.params.token;
  }

  validate(){
    if (this.password == '')
      return false;
    if (this.confirmPassword == '')
      return false;
    if (this.password != this.confirmPassword)
      return false;

    return true;
  }

  validatePassword(){
    if (this.password == '')
      return true;

    return validatePassword(this.password);
  }

  send(){
    this.spinner.show();
    
    var request = {
      password: this.password,
      token: this.token
    }

    this.userService.forgotPassword(request)
      .pipe(take(1)).subscribe(response => {
        this.spinner.hide();
        if (response.data){
          this.router.navigate(['/login']);
          Swal.fire(
            "Parabéns!",
            "Senha alterada com sucesso.",
            "success"
          )
        }
        else{
          Swal.fire(
            "Atenção!",
            response.validationErrors[0].errorMessage,
            "warning"
          )
        }
      }, () => {
        this.spinner.hide();
        Swal.fire(
          "Ops!",
          "Erro ao alterar a senha.",
          "error"
        )
      })
  }

}
