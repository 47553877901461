import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const url = environment.endpoint;

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(private http: HttpClient) { }

  openSchedule(schedules: any): Observable<any> {
    return this.http.post<any>(url + "/schedule/open", schedules, httpOptions);
  }

  getSchedule(doctorId: number, hospitalId: number, specialtyId: number, search: string, startDate: any, endDate: any): Observable<any> {
    return this.http.get<any>(url + "/schedule?doctorId=" + doctorId + "&hospitalId=" + hospitalId + "&specialtyId=" 
    + specialtyId + "&patient=" + search + "&startDate=" + startDate + "&endDate=" + endDate, httpOptions);
  }

  schedulePatient(schedule: any): Observable<any> {
    return this.http.post<any>(url + "/schedule/patient", schedule, httpOptions);
  }

  blockSchedule(id: any): Observable<any> {
    return this.http.post<any>(url + "/schedule/" + id + "/block", httpOptions);
  }

  reopenSchedule(id: any): Observable<any> {
    return this.http.post<any>(url + "/schedule/" + id + "/reopen", httpOptions);
  }

  confirmSchedule(id: any): Observable<any> {
    return this.http.post<any>(url + "/schedule/" + id + "/confirm", httpOptions);
  }

  selfSchedulePatient(schedule: any): Observable<any> {
    return this.http.post<any>(url + "/schedule/patient/self", schedule, httpOptions);
  }
}
