import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import Compress from "../../util/compress/Compress";

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit {

  isCameraOpened = false;
  isLoading: Boolean = false;

  compressConfig: any = {
    size: 4,
    quality: .85,
    maxWidth: 1920,
    maxHeight: 1920,
    resize: true,
  };

  @Input("facingMode") facingMode: string;
  @Output() onTakePicture = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  openCamera(facingMode?){
    if(facingMode){
      this.facingMode = facingMode;
    }
    
    this.openCameraFromInput();

  }

  openCameraFromInput(){
    setTimeout(()=>{
      this.isCameraOpened = true;
      document.getElementById('camera-upload').click();
    }, 0)
  }

  async changeListener($event) {
    this.isLoading= true;
    this.isCameraOpened = false;
    const compress = new Compress();
    const files = [...$event.target.files];
    compress.compress(files, this.compressConfig).then((data)=>{
      this.isLoading= false;
      let newImage = data[0].prefix + data[0].data;
      this.onTakePicture.emit({
        'imgBase64': newImage,
        'orientation': data[0].orientation,
        'fileName': data[0].alt
       });
    });
  }

}
