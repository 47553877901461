import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
import { mergeMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-search-specialty',
  templateUrl: './search-specialty.component.html',
  styleUrls: ['./search-specialty.component.scss']
})
export class SearchSpecialtyComponent implements OnInit {

  specialties: any[] = [];
  specialty: string;
  dataSource: Observable<any>;
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;

  @Input() specialtySelected: number;
  @Output('specialtyId') specialtyId = new EventEmitter<number>();
  @Output('specialtyName') specialtyName = new EventEmitter<string>();

  constructor(private commonService: CommonService) {
    this.dataSource = Observable.create((observer: any) => {
      observer.next(this.specialty);
    })
      .pipe(
        mergeMap((token: string) => this.getSpecialtiesAsObservable(token))
      );
   }

  ngOnInit() {
    this.getAllSpecialties();
  }

  getAllSpecialties(){
    
    this.commonService.getAllSpecialties()
    .pipe(take(1)).subscribe(response => {      
      this.specialties = response.data;
      this.setHospitalDefault();
    });
  }

  getSpecialtiesAsObservable(token: string): Observable<any> {
    const query = new RegExp(token, 'i');
 
    return of(
      this.specialties.filter((item: any) => {
        return query.test(item.name);
      })
    );
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.specialtyId.emit(e.item.id);
    this.specialtyName.emit(e.item.name); 
  }

  onChange(){
    if(this.specialty == "")
      this.specialtyId.emit(0);
      this.specialtyName.emit('');
  }

  setHospitalDefault(){
    if (this.specialtySelected != undefined){      
      this.specialties.filter((item: any) => {
        if (item.id == this.specialtySelected){
          this.specialty = item.name;          
           this.specialtyName.emit(item.name);
        }
      })
      this.specialtyId.emit(this.specialtySelected);
    }
  }

}
