import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
import { mergeMap, take } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/public_api';

@Component({
  selector: 'app-search-city',
  templateUrl: './search-city.component.html',
  styleUrls: ['./search-city.component.scss']
})
export class SearchCityComponent implements OnInit {

  cities: any[] = [];
  city: string;
  dataSource: Observable<any>;
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;

  @Input() cityDefault: string;
  @Output('cityName') cityName = new EventEmitter<string>();
  @Output('cityDescription') cityDescription = new EventEmitter<string>();

  constructor(
    private commonService: CommonService) { 
      this.dataSource = Observable.create((observer: any) => {
        observer.next(this.city);
      })
        .pipe(
          mergeMap((token: string) => this.getCitiesAsObservable(token))
        );
    }

    ngOnInit() {
      this.getAllCities();
    }
  
    getAllCities(){
      
      this.commonService.getAllCities()
      .pipe(take(1)).subscribe(response => {  
        this.cities = response.data;        
        this.setCityDefault();
      });
    }
  
    getCitiesAsObservable(token: string): Observable<any> {
      const query = new RegExp(token, 'i');
   
      return of(
        this.cities.filter((item: any) => {
          return query.test(item.description);
        })
      );
    }
  
    changeTypeaheadLoading(e: boolean): void {
      this.typeaheadLoading = e;
    }
  
    typeaheadOnSelect(e: TypeaheadMatch): void {
      this.cityName.emit(e.item.city);
      this.cityDescription.emit(e.item.description);
    }
  
    onChange(){
      if(this.city == "")
        this.cityName.emit('');
        this.cityDescription.emit('');
    }
  
    setCityDefault(){
      if (this.cityDefault != undefined){      
        this.cities.filter((item: any) => {
          if (item.city == this.cityDefault)
            this.city = item.description;
        })
        this.cityName.emit(this.cityDefault);
        this.cityDescription.emit(this.city);
      }
    }

}
