import { CommonService } from 'src/app/service/common.service';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DoctorService } from 'src/app/service/doctor.service';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-doctor-company',
  templateUrl: './doctor-company.component.html',
  styleUrls: ['./doctor-company.component.scss']
})
export class DoctorCompanyComponent implements OnInit {

  page: number = 1;
  pageSize: number = 10;
  search: string = '';
  totalItems: number = 0;
  doctors: any[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    private doctorService: DoctorService,
    private commonService: CommonService) { }

  ngOnInit() {
    this.getAllDoctors();
  }

  pageChanged(event: any){
    this.page = event.page;
    this.getAllDoctors();
  };

  getAllDoctors(){
    this.spinner.show();
    
    this.doctorService.getAllByCompanyPaged(this.page, this.pageSize, this.search)
    .pipe(take(1)).subscribe(response => {  
      if (response.data){
        this.doctors = response.data.results;
        this.totalItems = response.data.rowCount;
      }
      this.spinner.hide();
    });
  }

  getQrCode(){

    this.spinner.show();    
    
    this.commonService.getQRCodeCompany()
    .pipe(take(1)).subscribe(response => {        
      this.spinner.hide();

      Swal.fire({
        title: 'Novo Médico',
        html:
          '<p class="mb-4">Link para efetuar o cadastro de um novo médico.</p>' +          
          '<img src="data:image/png;base64,' + response.data.qrCode + '" /> <br />' +
          '<a class="small" target="_black" href="' + response.data.url + '">'+ response.data.url +'</a>',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: true,
        confirmButtonColor: '#36b9cc',
        confirmButtonText: 'Ok',
      });
    });
  }

  filterByText(value: string){
    this.search = value;
    this.page = 1;
    this.getAllDoctors();
  }

}
