import { administratorMenu, doctorMenu } from './../../models/const';
import { UserService } from './../../service/user.service';
import { CommonService } from './../../service/common.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { truncateText } from './../../util/util.js'
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ChangeCompanyComponent } from '../change-company/change-company.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  isToggled: boolean;
  user: any;
  menu: any;
  companyName: string;
  company: any;
  bsModalRef: BsModalRef;
  
  constructor(
    private router: Router,
    private userService: UserService,
    private commonService: CommonService,
    private modalService: BsModalService) { }

  ngOnInit() {
    this.getMenu();
  }

  getMenu(){
    this.user = JSON.parse(localStorage.getItem('currentUser'));

    if (this.user.companies && this.user.companies.length > 0){
      this.company = this.user.companies.filter(item => {
        if (item.id == this.user.companyId)
          return item;
      })[0];
      this.companyName = truncateText(this.company.name, 30);
    }

    this.menu = this.user.functions.filter(item => {
      if (item.menu == 'topbar')
        return item;
    });
  }

  toogleSidebar(){
    this.isToggled = !this.isToggled;
    this.commonService.changeResult(this.isToggled);
  } 

  logout(){
    this.userService.logout();
    this.router.navigate(['/login']);
  }

  changeCompany(): void {

    var initialState = {    
      companies: this.user.companies,
      comanySelectedId: this.user.companyId
    };

    this.bsModalRef = this.modalService.show(
      ChangeCompanyComponent, Object.assign({}, { 
        class: 'modal-md',
        initialState: initialState
      }
    ));

    this.modalService.onHidden
    .pipe(take(1))
    .subscribe(() => {      
      this.getMenu();
    })

  }; 

}
