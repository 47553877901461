import { isSameMonth, isSameDay } from 'date-fns';
import { Component, OnInit } from '@angular/core';
import { ShiftService } from 'src/app/service/shift.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { shiftStatus } from 'src/app/models/const';
import { CalendarEvent, CalendarView, CalendarDateFormatter } from 'angular-calendar';
import { Subject } from 'rxjs/internal/Subject';
import { CustomDateFormatter } from 'src/app/helpers/custom-date-formatter.provider';
import Swal from 'sweetalert2';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    }
  ]
})
export class CalendarComponent implements OnInit {

  viewDate: Date = new Date();
  minDate: Date = new Date();
  maxDate: Date = new Date();
  activeDayIsOpen: boolean = false;
  startDate: string = null;
  endDate: string = null;
  bsModalRef: BsModalRef;
  events: Array<CalendarEvent<{ shift: any }>> = [];
  refresh: Subject<any> = new Subject();
  locale: string = 'pt';
  view: CalendarView = CalendarView.Month;
  googleMaps: string = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyCvxNBlM7PQg2S5-GzbSnApbhrkeu6cB_4&q=';

  constructor(    
    private shiftService: ShiftService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService) { }

  ngOnInit() {
    this.commonService.changeResult(true);
    this.getAllByDoctor();
  }

  getAllByDoctor(){
    this.activeDayIsOpen = false;

    this.spinner.show();
    this.startDate = moment(new Date(this.viewDate)).startOf('month').format('YYYY-MM-DD');
    this.endDate = moment(new Date(this.viewDate)).endOf('month').format('YYYY-MM-DD');
    
    this.shiftService.getAllByDoctor(this.startDate, this.endDate)
    .pipe(take(1)).subscribe(response => {  
      if (response.data){
        this.events = response.data.map((shift: any) => {
          return {            
            title:  shift.hospital.name,
            start: new Date(moment(shift.startDate).format()),
            //end: new Date(moment(shift.endDate).format()),
            color: shiftStatus.filter(item => item.id == shift.status.id)[0].color,
            meta: {
              shift
            }
          }
        });
      }
      this.refresh.next();
      this.spinner.hide();
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  getQrCode(id: number){

    this.spinner.show();    
    
    this.shiftService.getQrCode(id)
    .pipe(take(1)).subscribe(response => {        
      this.spinner.hide();

      Swal.fire({
        title: 'Confirmar Plantão',
        icon: 'success',
        html:
          '<p>Para confirmar o plantão, <br>o gestor deverá ler o QR code abaixo:</p>' +
          '<img src="data:image/png;base64,' + response.data + '" />',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: true,
        confirmButtonColor: '#36b9cc',
        confirmButtonText: 'Fechar',
        confirmButtonAriaLabel: 'Plantão Confirmado!'
      }).then((result) => {
        this.getAllByDoctor();
      })

      
      
    });
  }

  canConfirm(shift: any){
    
    var now = moment();
    var startDate = moment(shift.startDate);
    var endDate = moment(shift.endDate);

    return now.isBetween(startDate, endDate);
  }

  confirmShift(shift: any){

    Swal.fire({
      title: 'Confirmar Plantão',
      icon: 'question',
      text: 'Deseja realmente confirmar o plantão?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      cancelButtonText: 'Não',
      confirmButtonColor: '#36b9cc',
      confirmButtonText: 'Sim',
    }).then((result) => {
      if (result.isConfirmed) {
        this.sendConfirmation(shift);
      }
      
    })

  }

  sendConfirmation(shift: any){
    this.spinner.show();    
    
    this.shiftService.confirm(shift.token)
    .pipe(take(1)).subscribe(response => {        
      this.spinner.hide();

      Swal.fire({
        title: 'Parabéns!',
        icon: 'success',
        text: 'Plantão Confirmado com sucesso.',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: true,
        confirmButtonColor: '#36b9cc',
        confirmButtonText: 'Fechar',
      }).then((result) => {
        this.getAllByDoctor();
      });      
      
    });
  }

}
