import { ProfileComponent } from './views/profile/profile.component';
import { RegisterVerifyComponent } from './views/register/register-verify/register-verify.component';
import { RegisterComponent } from './views/register/register.component';
import { ShiftSearchComponent } from './views/shift/shift-search/shift-search.component';
import { CalendarComponent } from './views/calendar/calendar.component';
import { ShiftComponent } from './views/shift/shift.component';
import { LoginComponent } from './views/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullLayoutComponent } from './containers/full-layout';
import { HomeComponent } from './views/home/home.component';
import { AuthGuard } from './guards/auth.guard';
import { ShiftConfirmComponent } from './views/shift/shift-confirm/shift-confirm.component';
import { DoctorComponent } from './views/doctor/doctor.component';
import { DoctorCompanyComponent } from './views/doctor/doctor-company/doctor-company.component';
import { DoctorBookChatComponent } from './views/doctor-book/doctor-book-chat/doctor-book-chat.component';
import { DoctorBookDiagnosticComponent } from './views/doctor-book/doctor-book-diagnostic/doctor-book-diagnostic.component';
import { DoctorBookTreatmentComponent } from './views/doctor-book/doctor-book-treatment/doctor-book-treatment.component';
import { PatientComponent } from './views/patient/patient/patient.component';
import { PatientMedicalRecordComponent } from './views/patient/patient-medical-record/patient-medical-record.component';
import { PatientCalendarComponent } from './views/patient/patient-calendar/patient-calendar.component';
import { PatientAppointmentComponent } from './views/patient/patient-appointment/patient-appointment.component';
import { PatientBookingComponent } from './views/patient/patient-booking/patient-booking.component';
import { RegisterAccountComponent } from './views/register/register-account/register-account.component';
import { CompanyComponent } from './views/company/company.component';
import { PaymentComponent } from './views/payment/payment.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ForgotPasswordChangeComponent } from './views/forgot-password/forgot-password-change/forgot-password-change.component';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';

const routes: Routes = [
  {
    path: '', component: LoginComponent, data: {title: 'Login'}
  },
  {
    path: 'login', component: LoginComponent, data: {title: 'Login'}
  },
  {
    path: 'esqueci-senha', component: ForgotPasswordComponent, data: {title: 'Esqueci Senha'}
  },
  {
    path: 'esqueci-senha/:token', component: ForgotPasswordChangeComponent, data: {title: 'Esqueci Senha'}
  },
  {
    path: 'cadastro', component: RegisterComponent, data: {title: 'Cadastro'}
  },
  {
    path: 'cadastro/conta', component: RegisterAccountComponent, data: {title: 'Cadastro'}
  },
  {
    path: 'cadastro/:token', component: RegisterComponent, data: {title: 'Cadastro'}
  },
  {
    path: 'validar/:token', component: RegisterVerifyComponent, data: {title: 'Validar Cadastro'}
  },
  {
    path: 'agendar/consulta/:identify', component: PatientBookingComponent, data: {title: 'Validar Cadastro'}
  },
  {
    path: 'chat', component: DoctorBookChatComponent, data: {title: 'Doctor Book'}
  },
  {
    path: 'home', component: FullLayoutComponent, data: {title: 'Home'},
    children: [
      { path: '', component: HomeComponent }
    ], canActivate: [AuthGuard]
  },
  {
    path: 'plantao', component: FullLayoutComponent, data: {title: 'Verificar Plantão'},
    children: [
      { path: '', component: ShiftComponent },
      { path: 'verificar', component: ShiftConfirmComponent },
      { path: 'buscar', component: ShiftSearchComponent }
    ], canActivate: [AuthGuard]
  },
  {
    path: 'agenda', component: FullLayoutComponent, data: {title: 'Agenda'},
    children: [
      { path: '', component: CalendarComponent }
    ], canActivate: [AuthGuard]
  },
  {
    path: 'perfil', component: FullLayoutComponent, data: {title: 'Meu Perfil'},
    children: [
      { path: '', component: ProfileComponent }
    ], canActivate: [AuthGuard]
  },
  {
    path: 'medico', component: FullLayoutComponent, data: {title: 'Médico'},
    children: [
      { path: '', component: DoctorCompanyComponent },
      { path: ':id', component: DoctorComponent }
    ], canActivate: [AuthGuard]
  },
  {
    path: 'doctor-book', component: FullLayoutComponent, data: {title: 'Hipótese Diagnóstica'},
    children: [
      { path: 'diagnostico', component: DoctorBookDiagnosticComponent },
      { path: 'tratamento', component: DoctorBookTreatmentComponent }
    ], canActivate: [AuthGuard]
  },
  {
    path: 'paciente', component: FullLayoutComponent, data: {title: 'Prontuários'},
    children: [
      { path: '', component: PatientComponent },
      { path: ':id/prontuario', component: PatientMedicalRecordComponent },
      { path: ':id/atendimento', component: PatientAppointmentComponent, canDeactivate: [CanDeactivateGuard] }
    ], canActivate: [AuthGuard]
  },
  {
    path: 'agendamento', component: FullLayoutComponent, data: {title: 'Agendamento'},
    children: [
      { path: '', component: PatientCalendarComponent }
    ], canActivate: [AuthGuard]
  },
  {
    path: 'clinica', component: FullLayoutComponent, data: {title: 'Clínica'},
    children: [
      { path: '', component: CompanyComponent }
    ], canActivate: [AuthGuard]
  },
  {
    path: 'plano', component: FullLayoutComponent, data: {title: 'Planos'},
    children: [
      { path: '', component: PaymentComponent }
    ], canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
