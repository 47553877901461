import { Component, OnInit } from '@angular/core';
import { CalendarView, CalendarDateFormatter, CalendarEvent } from 'angular-calendar';
import { Subject } from 'rxjs';
import { isMobile } from "../../../util/util.js";
import { ScheduleService } from 'src/app/service/schedule.service.js';
import { take } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { colors } from '../../../models/const';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DoctorOpenScheduleComponent } from 'src/app/components/doctor-open-schedule/doctor-open-schedule.component';
import { CustomDateFormatter } from 'src/app/helpers/custom-date-formatter.provider.js';
import { TitleCasePipe } from '@angular/common';
import { DoctorScheduleFormComponent } from 'src/app/components/doctor-schedule-form/doctor-schedule-form.component';
import { CommonService } from 'src/app/service/common.service.js';
import { DoctorService } from 'src/app/service/doctor.service.js';
import { scheduleStatus } from 'src/app/models/const';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-patient-calendar',
  templateUrl: './patient-calendar.component.html',
  styleUrls: ['./patient-calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    }
  ]
})
export class PatientCalendarComponent implements OnInit {

  viewDate: Date = new Date();
  locale: string = 'pt';
  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = false;  
  view: CalendarView = CalendarView.Week;
  bsModalRef: BsModalRef;
  events: any[] = [];
  patients: any[] = [];
  hourSegments: number =4
  user: any;
  doctorId: number = 0;
  hospitalId: number = 0;
  bsModalScheduleRef: BsModalRef;
  event: any;
  hospitals: any[];
  doctors: any[];
  viewSchedule: string = 'list'; 
  search: string = '';
  specialties: any[] = [];
  specialtyId:number = 0;
  
  constructor(
    private scheduleService : ScheduleService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private titlecasePipe:TitleCasePipe,
    private commonService : CommonService,
    private doctorService : DoctorService
  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('currentUser'));    
    if (this.user.doctor)
    this.doctorId = this.user.doctor.id;
    
    this.getSchedule();
    this.getAllHospital();
    this.getAllDoctors();
    this.getAllSpecialties();
  }

  isMobile(){
    return isMobile();
  }

  openSchedule(){

    var request = {};

    this.scheduleService.openSchedule(request)
    .pipe(take(1)).subscribe(response => { 
      this.spinner.hide(); 
      if (response.success){
        if (response.data){
          this.events = response.data.map((event: any) => {
            return {            
              title:  event.description + ' | ' + moment(event.startDate).format('HH:mm'),
              start: new Date(moment(event.startDate).format()),
              end: new Date(moment(event.endDate).format()),
              color: this.getColor(event.color),
              meta: {
                event
              }
            }
          });
  
        }
        else {
          this.events = [];
        }
      }
    },
    err =>{
      localStorage.removeItem('currentUser');
      //this.patient = null;
    });
  }

  getColor(statusId: number){
    var colorSelected = 'secondary';
    switch (statusId) {
      case 1:
        colorSelected = colors.secondary;
        break;
      case 2:
        colorSelected = colors.info;
        break;
      case 3:
        colorSelected = colors.success;
        break;
      case 4:
        colorSelected = colors.secondary;
        break;
      case 5:
        colorSelected = colors.warning;
        break;
      default:
        colorSelected = colors.info;
    }
    return colorSelected;
  }

  openModal(){
    var initialState = {    
      title: 'Abrir Agenda'
    };

    this.bsModalRef = this.modalService.show(
      DoctorOpenScheduleComponent, Object.assign({}, { 
        class: 'modal-lg',
        initialState: initialState
      }
    ));

    this.modalService.onHidden
    .pipe(
      take(1)
    )
    .subscribe(() => {      
      this.getSchedule();
    });
  }

  openModalSchedule(){
    var initialState = {    
      title: 'Agendamento',
      event: this.event
    };

    this.bsModalScheduleRef = this.modalService.show(
      DoctorScheduleFormComponent, Object.assign({}, { 
        class: 'modal-lg',
        initialState: initialState
      }
    ));

    this.modalService.onHidden
    .pipe(
      take(1)
    )
    .subscribe(() => {      
      this.getSchedule();
    });
  }

  getSchedule(){

    const today = moment(this.viewDate);

    var start = (this.isMobile() ? moment(this.viewDate, "DD-MM-YYYY").format() : moment(today.startOf('week'), "DD-MM-YYYY").format());
    var end = (this.isMobile() ? moment(this.viewDate, "DD-MM-YYYY").format() : moment(today.startOf('week').add(6, 'days'), "DD-MM-YYYY").format());


    this.scheduleService.getSchedule(this.doctorId, this.hospitalId, this.specialtyId, this.search, start, end)
    .pipe(take(1)).subscribe(response => { 
      this.spinner.hide(); 
      if (response.success){
        if (response.data){
          this.patients = response.data;
          this.events = response.data.map((event: any) => {
            return {            
              title: (event.scheduleStatusId == 5 ? 'Bloqueado' 
                        : (event.scheduleStatusId == 1 || !event.patient ? 'Em Aberto' + ' | ' + moment(event.startDate).format('HH:mm') 
                        : this.titlecasePipe.transform(event.patient.name) + ' | ' + moment(event.startDate).format('HH:mm'))),
              start: new Date(moment(event.startDate).format()),
              end: new Date(moment(event.endDate).format()),
              color: this.getColor(event.scheduleStatusId),
              meta: {
                event
              }
            }
          });
        }
        else {
          this.events = [];
          this.patients = [];
        }
      }
    });
  }

  eventClicked({ event }: { event: CalendarEvent }): void {
    this.event = event;
    this.openModalSchedule();
  }

  openEvent(schedule: any){
    var event = this.events.filter(item => item.meta.event.id==schedule.id)[0];
    this.event = event;
    this.openModalSchedule();
  }

  getAllHospital(){    
    this.commonService.getAllHospitals()
    .pipe(take(1)).subscribe(response => {      
      this.hospitals = response.data.filter(item => {
        item.name = this.titlecasePipe.transform(item.name);
        return item;
      });
    });
  }

  getAllSpecialties(){    
    this.commonService.getAllSpecialties()
    .pipe(take(1)).subscribe(response => {      
      this.specialties = response.data.filter(item => {
        item.name = this.titlecasePipe.transform(item.name);
        return item;
      });
    });
  }

  getAllDoctors(){    
    this.doctorService.getAllByCompany()
    .pipe(take(1)).subscribe(response => {
      if (response.data){
        this.doctors = response.data.filter(item => {
          item.name = this.titlecasePipe.transform(item.name);
          return item;
        }); 

        this.doctors = this.doctors.sort((a, b) => a.name.localeCompare(b.name));
      }
      else {
        this.doctors = [];
      }
           
    });
  }

  filterByText(value: string){
    this.search = value;
    this.getSchedule();
  }

  exportToExcel(): void {

    const excelData = this.patients.map(item => ({
      'Médico': item.doctor ? item.doctor.name : '',
      'Especialidade': item.doctor && item.doctor.specialties ? item.doctor.specialties[0].name : '',
      'Paciente': item.patient ? item.patient.name : '',
      'CPF': item.patient ? item.patient.cpf : '',
      'Status': item.scheduleStatus,
      'Local': item.hospital ? item.hospital.name : '',
      'Horário Inicio': this.formatDate(item.startDate),
      'Horário Fim': this.formatDate(item.endDate)
    }));

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelBlob: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    FileSaver.saveAs(excelBlob, 'agendamento.xlsx');
  }

  formatDate(date: string): string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    };
    return new Date(date).toLocaleDateString('pt-BR', options);
  }
}
