import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import { CompanyService } from 'src/app/service/company.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  payments: any[] = [];
  contract: any;

  constructor(
    private spinner: NgxSpinnerService,
    private companyService: CompanyService) { }

  ngOnInit() {
    this.getContract();
    this.getPayments();
  }

  upgradePlan(){}

  getContract(){
    this.spinner.show();
    this.companyService.getCurrentContract()
      .pipe(take(1)).subscribe(response => {
        this.spinner.hide();
        if (response.data)
          this.contract = response.data;
      },() => {
        this.spinner.hide();
        Swal.fire(
          "Ops!",
          "Erro ao buscar o contrato atual.",
          "error"
        )
      });
  }

  getPayments(){
    this.spinner.show();
    this.companyService.getAllPayment()
      .pipe(take(1)).subscribe(response => {
        this.spinner.hide();
        if (response.data)
          this.payments = response.data;
      },() => {
        this.spinner.hide();
        Swal.fire(
          "Ops!",
          "Erro ao buscar os pagamentos.",
          "error"
        )
      });
  }

}
