import { ShiftService } from 'src/app/service/shift.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BarcodeFormat } from '@zxing/library';
import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { take } from 'rxjs/operators';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-shift-confirm',
  templateUrl: './shift-confirm.component.html',
  styleUrls: ['./shift-confirm.component.scss']
})
export class ShiftConfirmComponent implements OnInit {
    
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;

  hasDevices: boolean;
  hasPermission: boolean;
  qrResultString: string;
  showCamera: boolean = true;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  constructor(
    private spinner: NgxSpinnerService,
    private shiftService: ShiftService,
    private commonService: CommonService
    ) { }

  ngOnInit() {    
    //this.commonService.changeResult(true);
  }

  // onCamerasFound(devices: MediaDeviceInfo[]): void {
  //   this.availableDevices = devices;
  //   this.hasDevices = Boolean(devices && devices.length);
  // }

  // onCodeResult(resultString: string) {
  //   this.qrResultString = resultString;
  //   this.showCamera = false;
  //   this.confirm();
  // }

  // confirm(){

  //   this.spinner.show();

  //   this.shiftService.confirm(this.qrResultString)
  //     .pipe(take(1)
  //     ).subscribe((res) => {

  //       this.spinner.hide();

  //       if (res.data){
  //         Swal.fire(
  //           'Parabéns!',
  //           'Plantão confirmado com sucesso.',
  //           'success'
  //         );
  //       }
  //       else {
  //         Swal.fire(
  //           'Atenção!',
  //           res.validationErrors[0].errorMessage
  //           ,
  //           'info'
  //         );
  //       }

        
        
  //     },
  //     err =>{

  //     });

  // }
  
}
