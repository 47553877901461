export function isMobile() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  let { userAgent } = navigator;

  return toMatch.some(toMatchItem => toMatchItem.test(userAgent));
}

export function movePageToTop() {
  let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
          window.scrollTo(0, pos - 20);
      } else {
          window.clearInterval(scrollToTop);
      }
  }, 16);
}

export function isIOS() {
  return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
}

export function rotateImage(imgBase64, degrees) {

  return new Promise(async (resolve, reject) => {
    const canvas = document.createElement('canvas');
    let image = new Image();

    image.onload = function () {
      let width = image.width;
      let height = image.height;

      const context = canvas.getContext('2d')

      context.clearRect(0, 0, canvas.width, canvas.height);

      // save the unrotated context of the canvas so we can restore it later
      context.save();

      if (degrees == 0 || degrees == 180) {
        canvas.width = width;
        canvas.height = height;
      }
      else {
        canvas.width = height;
        canvas.height = width;
      }
      // move to the center of the canvas
      context.translate(canvas.width / 2, canvas.height / 2);

      // rotate the canvas to the specified degrees
      context.rotate(degrees * Math.PI / 180);

      // draw the image
      // since the context is rotated, the image will be rotated also
      context.drawImage(image, -image.width / 2, -image.height / 2);
      context.restore();
      var myImage = canvas.toDataURL('image/jpeg');
      resolve(myImage);
    }
    image.src = imgBase64;
  });


}

export function calculateAge(birthday) { // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function validateCPF(cpf) {
  cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

  if (cpf.length !== 11 || /^(.)\1+$/.test(cpf)) {
    return false; // Verifica se o CPF possui 11 dígitos e se não é uma sequência de dígitos repetidos
  }

  var soma = 0;
  var resto;

  for (var i = 1; i <= 9; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }

  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) {
    resto = 0;
  }

  if (resto !== parseInt(cpf.substring(9, 10))) {
    return false; // Verifica se o primeiro dígito verificador está correto
  }

  soma = 0;

  for (i = 1; i <= 10; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }

  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) {
    resto = 0;
  }

  if (resto !== parseInt(cpf.substring(10, 11))) {
    return false; // Verifica se o segundo dígito verificador está correto
  }

  return true; // CPF válido
}

export function validateEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export function validatePassword(senha) {
  const regexLetraMaiuscula = /[A-Z]/;
  const regexNumero = /[0-9]/;
  const regexCaractereEspecial = /[!@#$%^&*()_\-+=<>?]/;

  if (senha.length < 8) {
    return false; // Senha tem menos de 8 caracteres
  }

  if (!regexLetraMaiuscula.test(senha)) {
    return false; // Senha não contém uma letra maiúscula
  }

  if (!regexNumero.test(senha)) {
    return false; // Senha não contém um número
  }

  if (!regexCaractereEspecial.test(senha)) {
    return false; // Senha não contém um caractere especial
  }

  return true; // Senha válida
}

export function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  }

  return text.substring(0, maxLength) + '...';
}