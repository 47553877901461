import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import { states } from 'src/app/models/const';
import { CommonService } from 'src/app/service/common.service';
import { PatientService } from 'src/app/service/patient.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.scss']
})
export class PatientFormComponent implements OnInit {

  title: string;
  patient: any;
  patientForm: FormGroup;
  submitted = false;
  errorLogin: boolean = false;
  validationError: string;
  states: any[];
  healthInsurances: any[] =[];
  birthDate: string;
  
  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private patientService: PatientService
  ) { }

  ngOnInit() {

    this.getHealthInsurance();

    this.patientForm = this.formBuilder.group({
      name: ['', Validators.required],
      cpf: ['', Validators.required],
      birthdate: [''],
      email: [''],
      phone: ['', Validators.required],
      gender: [''],
      healthInsuranceId: [0],
      postalCode: [''],
      address: [''],
      number: [''],
      complement: [''],
      district: [''],
      city: [''],
      state: ['']
    });

    if (this.patient)
      this.getPatient();

    this.states = states;
  }

  send(){
    if (this.patient && this.patient.id > 0){
      this.update();
    }
    else {
      this.save();
    }
  }

  save(){
    this.submitted = true;
    this.errorLogin = false;
    this.validationError = '';
    this.spinner.show();

    if (this.patientForm.invalid) {
      this.spinner.hide();
      return;
    }

    var request = {
      name: this.patientForm.controls.name.value,
      email: this.patientForm.controls.email.value,
      telephone: this.patientForm.controls.phone.value,
      cpf: this.patientForm.controls.cpf.value,
      birthdate: this.patientForm.controls.birthdate.value,
      gender: this.patientForm.controls.gender.value,
      healthInsuranceId: this.patientForm.controls.healthInsuranceId.value,
      address: this.patientForm.controls.address.value,
      number: this.patientForm.controls.number.value,
      complement: this.patientForm.controls.complement.value,
      district: this.patientForm.controls.district.value,
      city: this.patientForm.controls.city.value,
      state: this.patientForm.controls.state.value,
      postalcode: this.patientForm.controls.postalCode.value
    }

    this.patientService.add(request)
    .pipe(take(1))
    .subscribe(response => {

      this.spinner.hide();   
      if(response.data){
        this.bsModalRef.hide();
        this.event.emit(response.data);
        Swal.fire(
          'Sucesso!',
          'Paciente efetuado com sucesso.',
          'success'
        );
      }
      else{
        Swal.fire(
          'Atenção!',
          response.validationErrors[0].errorMessage,
          'warning'
        );
      } 
    }, () => {
      Swal.fire(
        'Ops!',
        'Ocorreu um erro ao cadastrar o paciente',
        'error'
      );
    });
  }

  update(){
    this.submitted = true;
    this.errorLogin = false;
    this.validationError = '';
    this.spinner.show();

    if (this.patientForm.invalid) {
      this.spinner.hide();
      return;
    }

    var request = {
      id: this.patient.id,
      name: this.patientForm.controls.name.value.trim().replace(/\s+/g, ' '),
      email: this.patientForm.controls.email.value,
      telephone: this.patientForm.controls.phone.value,
      cpf: this.patientForm.controls.cpf.value,
      birthdate: this.patientForm.controls.birthdate.value,
      gender: this.patientForm.controls.gender.value,
      healthInsuranceId: this.patientForm.controls.healthInsuranceId.value == 0 ? null : this.patientForm.controls.healthInsuranceId.value,
      address: this.patientForm.controls.address.value.trim(),
      number: this.patientForm.controls.number.value,
      complement: this.patientForm.controls.complement.value.trim(),
      district: this.patientForm.controls.district.value.trim(),
      city: this.patientForm.controls.city.value.trim(),
      state: this.patientForm.controls.state.value,
      postalcode: this.patientForm.controls.postalCode.value
    }

    this.patientService.update(request)
    .pipe(take(1))
    .subscribe(response => {

      this.spinner.hide();   
      if(response.data){
        this.bsModalRef.hide();
        this.event.emit(response.data);
        Swal.fire(
          'Sucesso!',
          'Dados atualizados com sucesso.',
          'success'
        );
      }
      else{
        Swal.fire(
          'Atenção!',
          response.validationErrors[0].errorMessage,
          'warning'
        );
      } 
    }, () => {
      Swal.fire(
        'Ops!',
        'Ocorreu um erro ao atualizar os dados do paciente',
        'error'
      );
    });
  }

  getAddress(){
    if(this.patientForm.controls.postalCode.value.length < 8)
      return;

    this.commonService.getAddress(this.patientForm.controls.postalCode.value)
    .pipe(take(1)).subscribe(response => {  
      if (!response.erro){
        this.patientForm.patchValue({
          address: response.logradouro,
          district: response.bairro,
          city: response.localidade,
          state: response.uf
        });
      }
    });
  }

  getHealthInsurance(){

    this.commonService.getAllHealthInsurance()
    .pipe(take(1)).subscribe(response => {  
      if (response.data){
        this.healthInsurances = response.data;
      }
    });
  }

  getPatient(){
    this.spinner.show()
    this.patientService.getById(this.patient.id)
    .pipe(take(1)).subscribe(response => {       
      this.spinner.hide(); 

      if (response.data){

        this.birthDate = (response.data.birthDate ? response.data.birthDate.split('T')[0] : '');

        this.patientForm.patchValue({
          name: response.data.name,
          cpf: response.data.cpf,
          birthDate: this.birthDate,
          email: response.data.email,
          phone: response.data.telephone,
          gender: response.data.gender,
          healthInsuranceId: (response.data.healthInsuranceId ? response.data.healthInsuranceId : 0),
          address: response.data.address,        
          number: response.data.number,
          complement: response.data.complement,
          district: response.data.district,
          city: response.data.city,
          state: response.data.state,
          postalCode: response.data.postalCode
        });
      }
    });
  }

  onSelectedData(date: any){
    this.birthDate = date;
  }
}
