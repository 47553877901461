import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const url = environment.endpoint;

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private http: HttpClient) { }

  getAll(page: number, pageSize: number, search: string): Observable<any> {
    return this.http.get<any>(url + "/patient/paged?page=" + page + "&pageSize=" + pageSize + "&search=" + search, httpOptions);
  }

  getById(id: number): Observable<any> {
    return this.http.get<any>(url + "/patient/" + id, httpOptions);
  }

  add(patient: any): Observable<any> {
    return this.http.post<any>(url + "/patient", patient, httpOptions);
  }

  update(patient: any): Observable<any> {
    return this.http.put<any>(url + "/patient", patient, httpOptions);
  }

  startAppointment(patient: any): Observable<any> {
    return this.http.post<any>(url + "/patient/appointment", patient, httpOptions);
  }

  updateAppointment(patient: any): Observable<any> {
    return this.http.put<any>(url + "/patient/appointment", patient, httpOptions);
  }

  finishAppointment(patient: any): Observable<any> {
    return this.http.put<any>(url + "/patient/appointment/finish", patient, httpOptions);
  }

  getAppointmentById(id: number): Observable<any> {
    return this.http.get<any>(url + "/patient/appointment/" + id, httpOptions);
  }

  getCurrentAppointment(patientId: number): Observable<any> {
    return this.http.get<any>(url + "/patient/" + patientId + "/appointment/current", httpOptions);
  }

  getCurrentAppointmentByCompany(): Observable<any> {
    return this.http.get<any>(url + "/patient/appointment/current", httpOptions);
  }
}
