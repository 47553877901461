import { doctorMenu } from './../../models/const';
import { ProfileDocumentComponent } from './profile-document/profile-document.component';
import { ProfileSelfieComponent } from './profile-selfie/profile-selfie.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { ProfilePersonalComponent } from './profile-personal/profile-personal.component';
import { take } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/service/user.service';
import Swal from 'sweetalert2';
import { ProfileChangePasswordComponent } from './profile-change-password/profile-change-password.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  user: any;
  bsModalRef: BsModalRef;
  crm: any[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private userService: UserService) { }

  ngOnInit() {
    this.getUser();
  }

  getUser(){
    this.spinner.show();
    this.userService.getUser()
      .pipe(take(1)).subscribe(response => {
        this.spinner.hide();
        if (response.data)
          this.user = response.data;
      }, () => {
        this.spinner.hide();
        Swal.fire(
          "Ops!",
          "Erro ao buscar usuário.",
          "error"
        )
      })
  }

  openPersonalData(): void {

    var initialState = {    
      user: this.user
    };

    this.bsModalRef = this.modalService.show(
      ProfilePersonalComponent, Object.assign({}, { 
        class: '',
        initialState: initialState
      }
    ));

    this.modalService.onHidden
    .pipe(
      take(1)
    )
    .subscribe(() => {      
      this.getUser();
    });

  }; 

  openSelfie(): void {

    var initialState = {    
      doctor: this.user.doctor
    };

    this.bsModalRef = this.modalService.show(
      ProfileSelfieComponent, Object.assign({}, { 
        class: '',
        initialState: initialState
      }
    ));

    this.modalService.onHidden
    .pipe(
      take(1)
    )
    .subscribe(() => {      
      this.user = JSON.parse(localStorage.getItem('currentUser'));
    });

  }; 

  openNewDocument(): void {

    var initialState = {    
      doctor: this.user.doctor
    };

    this.bsModalRef = this.modalService.show(
      ProfileDocumentComponent, Object.assign({}, { 
        class: '',
        initialState: initialState
      }
    ));

    this.modalService.onHidden
    .pipe(
      take(1)
    )
    .subscribe(() => {      
      this.user = JSON.parse(localStorage.getItem('currentUser'));
      this.crm = this.user.doctor.crm == null ? [] : this.user.doctor.crm;
    });

  };

  openChangePassword(): void {

    var initialState = {
    };

    this.bsModalRef = this.modalService.show(
      ProfileChangePasswordComponent, Object.assign({}, { 
        class: '',
        initialState: initialState
      }
    ));

  };

}
