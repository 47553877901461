import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import { CommonService } from 'src/app/service/common.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-company-hospital-form',
  templateUrl: './company-hospital-form.component.html',
  styleUrls: ['./company-hospital-form.component.scss']
})
export class CompanyHospitalFormComponent implements OnInit {

  hospital: any;

  constructor(
    public bsModalRef: BsModalRef,
    private commonService: CommonService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  getAddress(){
    if (this.hospital.postalCode.length < 8)
      return;
    this.commonService.getAddress(this.hospital.postalCode)
    .pipe(take(1)).subscribe(response => {
      this.hospital.address = response.logradouro;
      this.hospital.district = response.bairro;
      this.hospital.city = response.localidade;
      this.hospital.state = response.uf;
    });
  }

  validate(){
    if (this.hospital.name == '')
      return false;
    
    return true;
  }

  save(){
    this.spinner.show();
    this.commonService.editHospital(this.hospital)
    .pipe(take(1)).subscribe(() => {
      this.spinner.hide();
      this.bsModalRef.hide();
      Swal.fire(
        'Parabéns!',
        'Clínica alterada com sucesso.',
        'success'
      );
    }, () => {
      this.spinner.hide();
      Swal.fire(
        'Ops!',
        'Erro ao alterar a Clínica.',
        'error'
      );
    });
  }

}
