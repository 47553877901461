import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { listLocales } from 'ngx-bootstrap/chronos';
import { BsLocaleService, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import { DoctorService } from 'src/app/service/doctor.service';
import { ScheduleService } from 'src/app/service/schedule.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-patient-booking',
  templateUrl: './patient-booking.component.html',
  styleUrls: ['./patient-booking.component.scss']
})
export class PatientBookingComponent implements OnInit {

  dateSelected: any;
  bsConfig: Partial<BsDatepickerConfig>;
  minDate = new Date();
  selectedClass: DatepickerDateCustomClasses[];
  locale = 'pt-BR';  
  locales = listLocales();
  step: number = 1;
  identify: string;
  googleMaps: string = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyCvxNBlM7PQg2S5-GzbSnApbhrkeu6cB_4&q=';

  doctor: any;
  healthInsurances: any[] = [];
  healthInsurance: any;
  hospitals: any[] = [];
  openSchedules: any = [];
  openSchedulesFiltered: any = [];
  scheduleSelected: any;
  hospital: any;

  hospitalId: number = 0;
  healthInsuranceId: number = 0;

  patient: any = {
    name: '',
    cpf: '',
    birthDate: '',
    email: '',
    telephone: ''
  }
  
  constructor(
    private localeService: BsLocaleService,
    private doctorService: DoctorService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private scheduleService: ScheduleService
  ) { }

  ngOnInit() {
    this.dateSelected = new Date();
    this.bsConfig = Object.assign({}, { 
      containerClass: 'theme-blue',
      minDate: this.minDate 
    });
    this.localeService.use(this.locale);
    this.route.paramMap.subscribe(params => {
      const textParam = params.get('identify');
      this.identify = textParam;
    });
    this.getOpenSchedule();
  }

  filterObjects(): void {
    this.dateSelected.setHours(0, 0, 0, 0);
  
    this.openSchedulesFiltered = this.openSchedules.filter(obj => {
      const objectDate = new Date(obj.startDate);
      objectDate.setHours(0, 0, 0, 0);
  
      return objectDate.getTime() === this.dateSelected.getTime() && obj.hospitalId == this.hospitalId;
    });

    this.openSchedulesFiltered.map(item => {
      item.isSelected = false;
    })
  }

  selectObject(object): void {

    object.isSelected = true;
  
    this.openSchedulesFiltered.forEach(obj => {
      if (obj !== object) {
        obj.isSelected = false;
      }
    });
  
    this.scheduleSelected = object;
  }

  onValueChange(event) {
    this.dateSelected = event;
    this.filterObjects();
  }

  getOpenSchedule(){
    this.spinner.show();
    
    this.doctorService.getOpenSchedule(this.identify)
    .pipe(take(1)).subscribe(response => {  
      if (response.data){

        this.doctor = response.data.doctor;
        
        this.healthInsurances = response.data.healthInsurances;
        this.healthInsurance = this.healthInsurances[0];
        this.healthInsuranceId = this.healthInsurance.id;

        this.hospitals = response.data.hospitals;
        this.hospital = this.hospitals[0];
        this.hospitalId = this.hospital.id;
        
        this.openSchedules = response.data.doctor.schedules;
        this.filterObjects();

      }
      this.spinner.hide();
    }, () => {
      this.step=0;
      Swal.fire(
        'Ops!',
        'A agenda do médico não foi encontrada.',
        'info'
      );
    });
  }

  onSelectHosptital(){
    this.hospital = this.hospitals.filter(item => {
      if (item.id == this.hospitalId)
        return item;
    })[0];
    this.filterObjects();
  }

  onSelectHealthInsurance(){
    this.healthInsurance = this.healthInsurances.filter(item => {
      if (item.id == this.healthInsuranceId)
        return item;
    })[0];
  }

  validateSchedule(){
    if (this.hospitalId == 0)
      return false;
    
    if (this.healthInsuranceId == 0)
      return false;

    if (!this.scheduleSelected)
      return false;

    return true;
  }

  validatePatient(){
    if (this.patient.nome == '')
      return false;
    
    if (this.patient.cpf == '')
      return false;

    if (this.patient.birthDate == '')
      return false;

    if (this.patient.telephone == '')
      return false;
    
    return true;
  }

  schedulePatient(){
    this.spinner.show();
    var request = {
      scheduleId: this.scheduleSelected.id,
      name: this.patient.name,
      cpf: this.patient.cpf,
      birthDate: this.patient.birthDate,
      email: this.patient.email,
      telephone: this.patient.telephone
    }

    this.scheduleService.selfSchedulePatient(request)
    .pipe(take(1)).subscribe(response => {
      this.spinner.hide();
      if (response.data){
        this.step = 3;
      }
      else{
        Swal.fire(
          'Atenção!',
          response.validationErrors[0].errorMessage,
          'warning'
        );
      }
    }, () => {
      this.spinner.hide();
      Swal.fire(
        'Ops!',
        'Ocorreu um erro ao realizar o agendamento.',
        'error'
      );
    });


    
  }
}
