import { NgxSpinnerService } from 'ngx-spinner';
import { DoctorService } from './../../../service/doctor.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { CameraComponent } from 'src/app/components/camera/camera.component';
import { rotateImage, movePageToTop, isIOS } from "../../../util/util.js";

@Component({
  selector: 'app-register-verify',
  templateUrl: './register-verify.component.html',
  styleUrls: ['./register-verify.component.scss']
})
export class RegisterVerifyComponent implements OnInit {

  token: string;
  validationError: string = '';
  success: boolean = true;
  step: number = 1;

  isCameraOpened: boolean = false;
  previewUrl:any = null;
  originalPreview: any = null;
  degrees: number = 0;
  uploadSuccess: boolean = false;
  fileName: string;

  request: any = {
    token: '',
    imageSelfie: {
      fileName:'',
      fileBase64: ''
    },
    imageDocumentFront: {
      fileName:'',
      fileBase64: ''
    },
    imageDocumentBack: {
      fileName:'',
      fileBase64: ''
    }
  };

  constructor(
    private spinner: NgxSpinnerService,
    private doctorService: DoctorService,
    private route: ActivatedRoute) { }

    @ViewChild('cameraComponent', {static: false}) cameraComponent: CameraComponent;

  ngOnInit() {

    this.spinner.show();

    this.token = this.route.snapshot.paramMap.get("token");
    this.request.token = this.token;
    

    this.doctorService.verify(this.token)
      .pipe(take(1))
      .subscribe(response => {

        this.spinner.hide();

        if(response.data){
          this.success = true;
        }
        else {
          this.success = false;
          this.validationError = response.validationErrors[0].errorMessage;
        } 
      });

  }

  openCamera(){
    this.isCameraOpened = true;
    setTimeout(()=>{
      this.cameraComponent.onTakePicture.subscribe(async(data) =>{
        const { imgBase64, orientation, fileName } = data;
        this.fileName = fileName;
        this.previewUrl = imgBase64;
        this.originalPreview = this.previewUrl;
        this.isCameraOpened = false;

        this.step = this.step+1;

        if(isIOS()){          
          if(orientation === 8){
            this.degrees = 90;
            this.previewUrl = await rotateImage(this.originalPreview,this.degrees);
          }
          else if(orientation === 6){
            this.degrees = 270;
            this.previewUrl = await rotateImage(this.originalPreview, this.degrees);
          }
        }

      });

      setTimeout(()=>{
        movePageToTop();
      }, 1);
      this.cameraComponent.openCamera();
    },1);

  }

  async handleRotateImage(){
    this.degrees+=90;
    if (this.degrees==360)
      this.degrees=0;
    this.previewUrl = await rotateImage(this.originalPreview, this.degrees);
  }

  cancelImage(){
    this.step = this.step-1;
    this.previewUrl = null;
    this.originalPreview = null;
    this.openCamera();
  }
  
  selectSelfie(){
    this.request.imageSelfie.fileName=this.fileName;
    this.request.imageSelfie.fileBase64=this.previewUrl;
    this.step = 4;
  }

  selectDocumentFront(){
    this.request.imageDocumentFront.fileName=this.fileName;
    this.request.imageDocumentFront.fileBase64=this.previewUrl;
    this.openCamera();
  }

  save(){
    console.log(this.request);
    this.request.imageDocumentBack.fileName = this.fileName;
    this.request.imageDocumentBack.fileBase64 = this.previewUrl;
    this.spinner.show();

    this.doctorService.updateDocument(this.request)
      .pipe(take(1))
      .subscribe(response => {

        this.spinner.hide();

        if(response.data){
          this.step = 7;
          this.success = true;
        }
        else {
          this.step = 7;
          this.success = false;
          this.validationError = response.validationErrors[0].errorMessage;
        } 
      });
    
  }

}
