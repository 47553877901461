import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from '../service/user.service';
import Swal from 'sweetalert2';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private userService: UserService,
        private spinner: NgxSpinnerService
        ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                
                this.userService.logout();
                location.reload(true);
            }
            
            const error = err.error.message || err.statusText;
            
            this.spinner.hide();
            Swal.fire(
                'Ops!',
                'Ocorreu um erro ao processar sua solicitação.',
                'error'
              );

            return throwError(error);
        }))
    }
}