import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import decode from 'jwt-decode';

@Injectable()
export class AuthGuard implements CanActivate {

    user: any;

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        this.user = JSON.parse(localStorage.getItem('currentUser'));
        
        if (!localStorage.getItem('currentUser')) {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
            return false;
        }
        
        const expectedRole = route.data.expectedRole;        
        const tokenPayload = decode(this.user.token);

        if (expectedRole != null && !expectedRole.includes(tokenPayload.role)){
            this.router.navigate(['/404'], { queryParams: { returnUrl: state.url }});
            return false;
        }

        return true;        
    }
}