import { NgxSpinnerService } from 'ngx-spinner';
import { ShiftService } from 'src/app/service/shift.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { take, map, catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-shift-new',
  templateUrl: './shift-new.component.html',
  styleUrls: ['./shift-new.component.scss']
})
export class ShiftNewComponent implements OnInit {

  viewDate: Date = new Date();
  minDate: any;
  doctorDefined: boolean = true;
  messageValidation: string;

  shift: any = {
    hospitalId: 0,
    specialtyId: 0,
    doctorId: 0,
    dates: []
  };

  shiftDate: any;
  isShiftVerify: boolean;
  

  constructor(
    public bsModalRef: BsModalRef,
    public shiftService: ShiftService,
    public spinner: NgxSpinnerService) {  }

  ngOnInit() {
    this.setDateDefault();
    this.minDate = moment().format('YYYY-MM-DD');
  }

  addShiftDate(){
    
      this.shift.dates.push({
        date: this.shiftDate.date,
        hour: this.shiftDate.hour,
        duration: this.shiftDate.duration,
        value: this.shiftDate.value
      });
      
      this.setDateDefault();
  }

  removeShiftDate(item: any){
    const index = this.shift.dates.indexOf(item);
    if (index > -1) {
      this.shift.dates.splice(index, 1);
    }
  }

  cleanDoctorId(){
    if (!this.doctorDefined){
      this.shift.doctorId = 0;
    }
  }

  validShiftDateRequired(){
    //Date
    if (this.shiftDate.date == null)
      return false;    

    //Hour
    if (this.shiftDate.hour == '' || this.shiftDate.hour == null){   
      return false;
    }
    
    //Duration
    if (this.shiftDate.duration == null)
      return false;    

    //Value
    if (this.shiftDate.value == null)
      return false;

    return true;
  }

  validShiftDate(){
    //Date
    if (moment(this.minDate).isAfter(this.shiftDate.date)){
      this.messageValidation = 'A data do plantão deverá ser maior ou igual a data de hoje.';
      this.shiftDate.date = null;
      return false;
    }

    //Hour
    if (this.shiftDate.hour == '' || this.shiftDate.hour == null){
      this.messageValidation = 'A hora de início do plantão deverá ser obrigatório';      
      return false;
    }
    
    if (this.shiftDate.duration > 12){
      this.messageValidation = 'A duração do plantão deverá ser de até 12 horas.';
      return false;
    }

    if (this.shiftDate.duration < 4){
      this.messageValidation = 'A duração do plantão deverá ser de no mínimo 4 horas.';
      return false;
    }

    return true;
  }

  setDateDefault(){
    this.shiftDate = {
      date: null,
      hour: '07:00',
      duration: null,
      value: null
    }
  }

  validShift(){
    if (this.shift.hospitalId == 0)
      return false;

    if (this.shift.specialtyId == 0)
      return false;
    
    if (this.shift.dates.length == 0)
      return false;

    return true;
  }

  getHospital(value: number){
    this.shift.hospitalId = value;
  }

  getSpecialty(value: number){
    this.shift.specialtyId = value;
  }

  getDoctor(value: number){
    this.shift.doctorId = value;
    this.shift.dates = [];
  }

  verifyDoctorShift(){

    this.messageValidation = '';

    if (this.shift.doctorId == 0 && this.validShiftDate()){
      this.addShiftDate();
      return;
    }

    var newStartDate = moment(this.shiftDate.date + ' ' + this.shiftDate.hour, "YYYY-MM-DD HH:mm"); 
    
    var shift = this.shift.dates.filter(item => {
        var startDate = moment(item.date + ' ' + item.hour, "YYYY-MM-DD HH:mm").toDate();
        var endDate = new Date(startDate);
        endDate = moment(endDate).add(item.duration, 'h').toDate();
        if (newStartDate.isSameOrAfter(startDate) && newStartDate.isBefore(endDate)){
          return item;
        }            
    });

    if (shift.length > 0){
      this.messageValidation = 'Já existe um plantão para este médico nesta data e período';
      return;
    }
    else {
      
      if (!this.validShiftDate())
        return;

      var request = {
        hospitalId: this.shift.hospitalId,
        doctorId: this.shift.doctorId,
        specialtyId: this.shift.specialtyId,
        startDate: newStartDate.format(),
        endDate: moment(newStartDate).add(this.shiftDate.duration, 'h').format(),
        value: this.shiftDate.value
      }

      this.shiftService.verify(request)
      .pipe(take(1)
      ).subscribe((res) => {
        if(res.data){
          this.addShiftDate();
          return;
        }

        if(res.validationErrors) {
          this.messageValidation = res.validationErrors[0].errorMessage;
          return;
        }
        else{
          this.messageValidation = 'Já existe um plantão para este médico nesta data e período';
        }
      },
      err =>{
        this.messageValidation = err;
      });
    }
  }

  save(){

    this.spinner.show();

    var request = {
      hospitalId: this.shift.hospitalId,
      doctorId: this.shift.doctorId,
      specialtyId: this.shift.specialtyId,
      dates: []
    }

    this.shift.dates.filter(item => {
      var newStartDate = moment(item.date + ' ' + item.hour, "YYYY-MM-DD HH:mm");
      var date = {
        startDate: newStartDate.format(),
        endDate: moment(newStartDate).add(item.duration, 'h').format(),
        value: item.value
      }
      request.dates.push(date);
    });

    this.shiftService.addRange(request)
      .pipe(take(1)
      ).subscribe((res) => {

        this.spinner.hide();
        this.bsModalRef.hide();

        Swal.fire(
          'Parabéns!',
          'Plantões adicionados com sucesso!',
          'success'
        );
        
      },
      err =>{
        this.messageValidation = err;
      });

  }

}
