import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import { PatientService } from 'src/app/service/patient.service';
import { PatientFormComponent } from '../patient-form/patient-form.component';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss']
})
export class PatientComponent implements OnInit {

  search: string = '';
  page: number = 1;
  pageSize: number = 10;
  totalItems: number;
  patients: any[] = [];
  bsModalRef: BsModalRef;
  currentAppointment: any;
  user: any;

  constructor(
    private spinner: NgxSpinnerService,
    private patientService: PatientService,
    private modalService: BsModalService,
    private router: Router
  ) { }

  ngOnInit() {    
    this.spinner.show();
    this.getAllPatients();
    this.getCurrentAppointmentByCompany();
    this.user = JSON.parse(localStorage.getItem('currentUser'));
  }

  filterByText(value: string){
    this.search = value;
    this.page = 1;
    this.getAllPatients();
  }

  getAllPatients(){
    
    this.patientService.getAll(this.page, this.pageSize, this.search)
    .pipe(take(1)).subscribe(response => {  
      if (response.data){
        this.patients = response.data.results;
        this.totalItems = response.data.rowCount;
      }
      this.spinner.hide();
    });
  }

  getCurrentAppointmentByCompany(){
    
    this.patientService.getCurrentAppointmentByCompany()
    .pipe(take(1)).subscribe(response => { 
      
      this.spinner.hide(); 
      if (response.data){
        Swal.fire({
          title: 'Atendimento em Andamento',
          text: 'Exite um atendimento em andamento. Deseja ir para o atendimento?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Fechar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate([`/paciente/${response.data.patientId}/atendimento`]);
          }
        });
      }
    });
  }

  pageChanged(event: any){
    this.page = event.page;
    this.getAllPatients();
  };

  openNewPatient(): void {
    
    var initialState = {    
      title: 'Novo Paciente'
    };

    this.bsModalRef = this.modalService.show(
      PatientFormComponent, Object.assign({}, { 
        class: 'modal-lg',
        initialState: initialState,
        ignoreBackdropClick: true
      }
    ));

    this.bsModalRef.content.event.subscribe(() => {
      this.getAllPatients();
    });
  };

  openEditPatient(patient: any): void {
    
    var initialState = {    
      title: 'Editar Paciente',
      patient: patient 
    };

    this.bsModalRef = this.modalService.show(
      PatientFormComponent, Object.assign({}, { 
        class: 'modal-lg',
        initialState: initialState
      }
    ));

    this.bsModalRef.content.event.subscribe(() => {
      this.getAllPatients();
    });
  }; 

}
