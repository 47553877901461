import { Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Options } from 'ng5-slider';
import * as moment from 'moment';

@Component({
  selector: 'app-shift-search-filter',
  templateUrl: './shift-search-filter.component.html',
  styleUrls: ['./shift-search-filter.component.scss']
})
export class ShiftSearchFilterComponent implements OnInit {

  filter: any;
  minDate: string =  moment().format('YYYY-MM-DD');

  options: Options = {
    floor: 300,
    ceil: 3000,
    step: 100,
    showOuterSelectionBars: false
  };

  @Input() filterRequest: any;
  @Output('filterResponse') filterResponse = new EventEmitter<any>();

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  getCity(value: string){
    this.filter.city = value;
  }

  getCityDescription(value: string){
    this.filter.cityDescription = value;
  }

  getSpecialty(value: number){
    this.filter.specialtyId = value;
  }

  getSpecialtyName(value: string){
    this.filter.specialty = value;
  }

  send(){
    this.bsModalRef.hide();
    this.filterResponse.emit(this.filter);
    console.log(this.filter)
  }

  clean(){
    this.filter.startDate = moment().format('YYYY-MM-DD');
    this.filter.endDate =  moment(this.filter.startDate).add(1, 'month').format('YYYY-MM-DD');
    this.filter.specialtyId = 0;
    this.filter.value = 300;
    this.filter.highValue = 3000;
    this.filterResponse.emit(this.filter);
    this.bsModalRef.hide();
  }

}
