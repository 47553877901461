import { Component, OnInit } from '@angular/core';
import { calculateAge, isMobile } from "../../../util/util.js";
import { PatientService } from 'src/app/service/patient.service.js';
import { take } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PatientAppointmentComponent } from '../patient-appointment/patient-appointment.component';
import Swal from 'sweetalert2';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-patient-medical-record',
  templateUrl: './patient-medical-record.component.html',
  styleUrls: ['./patient-medical-record.component.scss']
})
export class PatientMedicalRecordComponent implements OnInit {

  user: any;
  doctorId: number = 0;
  patient: any;
  patientId: number = 0;
  bsModalRef: BsModalRef;
  appointment: any;
  appointments: any[];
  inAttendance: boolean;
  sameDoctor: boolean;

  constructor(
    private patientService : PatientService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private titlecasePipe:TitleCasePipe,
    private router: Router
  ) { }

  ngOnInit() {
    this.getCurrentAppointmentByCompany();
    
    this.patientId = this.route.snapshot.params.id;

    this.user = JSON.parse(localStorage.getItem('currentUser'));
    if (this.user.doctor)
      this.doctorId = this.user.doctor.id;

    this.getPatient();
  }

  getPatient(){
    this.spinner.show()
    this.patientService.getById(this.patientId)
    .pipe(take(1)).subscribe(response => {  
      if (response.data){
        this.patient = response.data;
        this.appointments = response.data.appointments;
        if (this.appointments){
          this.appointments = response.data.appointments.sort((a, b) => (a.id < b.id) ? 1 : -1);

          //Verify patient in attendance
          this.inAttendance = (this.appointments.filter(item => { return item.endDate == null}).length > 0);
          this.sameDoctor = (this.appointments.filter(item => { return item.endDate == null && this.doctorId == item.doctorId}).length > 0);
        }
          
      }
      this.spinner.hide();
    });
  }

  startAppointment(){
    if (this.inAttendance)
      return;
    
    Swal.fire({
      title: 'Iniciar Atendimento',
      icon: 'question',
      html:
        '<p>Deseja realmente iniciar atendimento para o paciente '
        + '<b>' + this.titlecasePipe.transform(this.patient.name) + '</b>?</p>',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonColor: '#1cc88a',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed){
        
        this.spinner.show();

        var request = {
          patientId: this.patient.id
        }

        this.patientService.startAppointment(request)
        .pipe(take(1)).subscribe(response => { 
          this.spinner.hide(); 
          if (response.data){
            this.appointment = response.data;
            this.sendToAppointment()
          }
          else {
            Swal.fire(
              'Atenção!',
              response.validationErrors[0].errorMessage,
              'warning'
            )
          }
          
        }, () => {
          this.spinner.hide();
          Swal.fire(
            'Ops!',
            'Ocorreu um erro ao tentar iniciar o atendimento.',
            'warning'
          )
        });
      }
    })
  }

  sendToAppointment(){
    this.router.navigate([`/paciente/${this.patientId}/atendimento`]);
  }

  getAge(age: string){
    return calculateAge(new Date(age))
  }

  getCurrentAppointmentByCompany(){
    
    this.patientService.getCurrentAppointmentByCompany()
    .pipe(take(1)).subscribe(response => { 
      
      this.spinner.hide(); 
      if (response.data){
        Swal.fire({
          title: 'Atendimento em Andamento',
          text: 'Exite um atendimento em andamento. Deseja ir para o atendimento?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Fechar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate([`/paciente/${response.data.patientId}/atendimento`]);
          }
        });
      }
    });
  }

}
