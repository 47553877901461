import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TitleCasePipe } from '@angular/common';
import { states } from 'src/app/models/const';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-profile-personal',
  templateUrl: './profile-personal.component.html',
  styleUrls: ['./profile-personal.component.scss']
})
export class ProfilePersonalComponent implements OnInit {

  personalForm: FormGroup;
  states: any;
  validationError: string;
  user: any;
  
  constructor(
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private titlecasePipe:TitleCasePipe,
    private formBuilder: FormBuilder,
    public bsModalRef: BsModalRef) { }

  ngOnInit() {

    this.states = states;

    this.personalForm = this.formBuilder.group({
      name: [{value: this.titlecasePipe.transform(this.user.name), disabled: true}, Validators.required],
      cpf: [{value: this.user.cpf, disabled: true}, Validators.required],
      email: [this.user.email, Validators.required],
      phone: [this.user.phone, Validators.required]
    });

  }

  get f() { return this.personalForm.controls; }

  onSubmit(){

    this.validationError = '';
    this.spinner.show();

    if (this.personalForm.invalid) {
      this.spinner.hide();
      return;
    }

    var request = {
      id: this.user.id,
      name: this.f.name.value,
      cpf: this.f.cpf.value,
      email: this.f.email.value,
      phone: this.f.phone.value
    }

    this.userService.editUser(request)
      .pipe(take(1))
      .subscribe(() => {        
        
        this.updateUserStorage();
        this.spinner.hide();
        this.bsModalRef.hide();

          Swal.fire(
            'Parabés!',
            'Perfil atualizado com sucesso.',
            'success'
          ); 
      },
      err => {

        this.spinner.hide(); 

        Swal.fire(
          'Ops!',
          err,
          'error'
        );
      });

  }

  updateUserStorage(){

    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.user.email = this.f.email.value;
    this.user.phone = this.f.phone.value;

    localStorage.removeItem('currentUser');
    localStorage.setItem('currentUser', JSON.stringify(this.user));
  }

}
