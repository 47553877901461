import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import { DoctorBookService } from 'src/app/service/doctor-book.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-doctor-book-treatment',
  templateUrl: './doctor-book-treatment.component.html',
  styleUrls: ['./doctor-book-treatment.component.scss']
})
export class DoctorBookTreatmentComponent implements OnInit {
  treatmentPhrase: string = 'Qual o tratamento farmacológico para os casos de  ';

  disease: string;
  resultExam: string;
  resultTreatment: string;

  lastSearch: string;

  constructor(
    private spinner: NgxSpinnerService,
    private doctorBookService: DoctorBookService
  ) { }

  ngOnInit() {
  }

  getDideaseDetails(){
    if (!this.disease)
      return;
    this.getTreatment();
  }

  getTreatment(){    

    this.resultTreatment = '';
    
    this.spinner.show();
    var args = this.treatmentPhrase + this.disease;
    
    this.doctorBookService.getDiagnostic(args)
    .pipe(take(1)).subscribe(response => {
      this.spinner.hide();
      if (response.data){
        this.resultTreatment = response.data;
      }
      else {
        Swal.fire(
          'Atenção!',
          'Ocorreu um erro ao pesquisar sua resposta.',
          'warning'
        );
      }
      if (this.resultExam)
        this.spinner.hide();
    }, err => {
      this.spinner.hide();
        Swal.fire(
          'Ops!',
          'Ocorreu um erro ao pesquisar sua resposta.',
          'error'
        );
    });
  }

  clear(){
    this.resultExam = '';
    this.resultTreatment = '';
    this.disease = '';
  }

  getDisease(value: string){
    this.disease = value;
  }

}
