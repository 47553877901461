import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/service/user.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  errorLogin: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      login: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.userService.logout()
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.errorLogin = false;
    this.spinner.show();

    if (this.loginForm.invalid) {
      this.spinner.hide();
      return;
    }

    this.userService.login(
      this.loginForm.controls.login.value, 
      this.loginForm.controls.password.value)
      .pipe(take(1))
      .subscribe( () => {
        
        this.spinner.hide();

        var user = JSON.parse(localStorage.getItem('currentUser'));

        this.router.navigate(['home']);
        
      },
      erro => {
          this.spinner.hide();
          this.errorLogin = true;
      });
  }

}
