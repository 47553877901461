import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { Observable, Observer, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { PatientService } from 'src/app/service/patient.service';

@Component({
  selector: 'app-search-patient',
  templateUrl: './search-patient.component.html',
  styleUrls: ['./search-patient.component.scss']
})
export class SearchPatientComponent implements OnInit {

  patients: any[] = [];
  patient: string;
  dataSource: Observable<any>;
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;
  errorMessage: string;

  @Input() patientSelected: string;
  @Output('patientObject') patientObject = new EventEmitter<any>();
  @Output('patientName') patientName = new EventEmitter<string>();
  
  constructor(
    private patientService: PatientService
  ) { }

  ngOnInit(): void {
    this.dataSource = new Observable((observer: Observer<string>) => {
      observer.next(this.patient);
    }).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.patientService.getAll(1, 10, this.patient).pipe(
            map((response: any) => response && response.data.results || []),
            tap(() => {}, err => {
              // in case of http error
              this.errorMessage = err && err.message || 'Something goes wrong';
            })
          );
        }
 
        return of([]);
      })
    );
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  onChange(){
    if(this.patient == "")
      this.patientObject.emit(null);
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.patientObject.emit(e.item);
  }

  typeaheadOnBlur(): void {
    this.patientName.emit(this.patient);
  }

}
