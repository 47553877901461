import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { states } from 'src/app/models/const';
import { validateCPF, validateEmail, validatePassword } from "../../../util/util.js";
import { CommonService } from 'src/app/service/common.service.js';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { TabsetComponent } from 'ngx-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-account',
  templateUrl: './register-account.component.html',
  styleUrls: ['./register-account.component.scss']
})
export class RegisterAccountComponent implements OnInit {

  
  states: any[];
  typePlan: string = 'mensal';
  selectedPlan: any;
  valuePlan: any;
  planList: any[];
  selectedPrice: number = 0;
  personType: string = 'fisica';
  companyName: string = '';
  cnpj: string = '';
  name: string = '';
  cpf:string = '';
  clinicRoleId: number = 0;
  crm: string = '';
  crmState: string = '';
  specialtyId: number = 0;
  email: string = '';
  phone: string = '';
  password: string = '';
  confirmPassword:string = '';
  documentMask: string = '00000000000';
  mercadoPago: any;
  isProccessed: boolean = false;
  isClicked: boolean = false;

  @ViewChild('registrationTabs', { static: false }) registrationTabs: TabsetComponent;

  constructor(
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private router: Router
  ) { }

  ngOnInit() {
    this.states = states;

    this.planList = [
      { id: 1, name: 'free', monthValue: 0, yearValue: 0 },
      { id: 2, name: 'essencial', monthValue: 59.90, yearValue: 649.90 },
      { id: 3, name: 'standard', monthValue: 99.90, yearValue: 1078.90 },
      { id: 4, name: 'premium', monthValue: 149.90, yearValue: 1618.90 }
    ];

    this.selectPlan('standard');
  }

  setupMercadoPago(){    
    this.mercadoPago = window["MercadoPago"];
    const mp = new this.mercadoPago("APP_USR-3d2a76c4-ce14-4bb1-ac7d-51d0d8620030");
    
    const cardForm = mp.cardForm({
      amount: this.selectedPrice.toString(),
      iframe: true,
      form: {
        id: "form-checkout",
        cardNumber: {
          id: "form-checkout__cardNumber",
          placeholder: "Número do cartão",
        },
        expirationDate: {
          id: "form-checkout__expirationDate",
          placeholder: "mm/aa",
        },
        securityCode: {
          id: "form-checkout__securityCode",
          placeholder: "Código de segurança",
        },
        cardholderName: {
          id: "form-checkout__cardholderName",
          placeholder: "Titular do cartão",
        },
        issuer: {
          id: "form-checkout__issuer",
          placeholder: "Banco emissor",
        },
        installments: {
          id: "form-checkout__installments",
          placeholder: "Parcelas",
        },        
        identificationType: {
          id: "form-checkout__identificationType",
          placeholder: "Tipo de documento",
        },
        identificationNumber: {
          id: "form-checkout__identificationNumber",
          placeholder: "Número do documento",
        },
        cardholderEmail: {
          id: "form-checkout__cardholderEmail",
          placeholder: "E-mail",
        },
      },
      callbacks: {
        onFormMounted: error => {
          if (error) return console.warn("Form Mounted handling error: ", error);
          console.log("Form mounted");
        },
        onSubmit: event => {
          event.preventDefault();

          const {
            paymentMethodId: payment_method_id,
            issuerId: issuer_id,
            cardholderEmail: email,
            amount,
            token,
            installments,
            identificationNumber,
            identificationType,
          } = cardForm.getCardFormData();

          
          this.proccessPayment({
            token,
            issuer_id,
            payment_method_id,
            transaction_amount: Number(amount),
            installments: Number(installments),
            description: this.selectedPlan.name,
            payer: {
              email,
              identification: {
                type: identificationType,
                number: identificationNumber,
              },
            },
          })

          // fetch("/process_payment", {
          //   method: "POST",
          //   headers: {
          //     "Content-Type": "application/json",
          //   },
          //   body: JSON.stringify({
          //     token,
          //     issuer_id,
          //     payment_method_id,
          //     transaction_amount: Number(amount),
          //     installments: Number(installments),
          //     description: "Descrição do produto",
          //     payer: {
          //       email,
          //       identification: {
          //         type: identificationType,
          //         number: identificationNumber,
          //       },
          //     },
          //   }),
          // });
        },
        onFetching: (resource) => {
          console.log("Fetching resource: ", resource);

          // Animate progress bar
          const progressBar = document.querySelector(".progress-bar");
          progressBar.removeAttribute("value");

          return () => {
            progressBar.setAttribute("value", "0");
          };
        }
      },
    });

  }

  validatePersonal(){
    if (this.name == '')
      return false;

    if (this.cpf == '' || !this.validateCPF(this.cpf))
      return false;

    if (this.clinicRoleId == 0)
      return false;
    
    if (this.email == '' || !this.validateEmail(this.email))
      return false;

    if (this.phone == '')
      return false;

    if (this.password == '')
      return false;

    if (this.confirmPassword != this.password)
      return false;

    return true;
  }

  getSpecialtyId(id: any){
    this.specialtyId = id;
  }

  validateCPF(cpf: string){
    if (this.cpf == '')
      return true;

    return validateCPF(cpf);
  }

  validateEmail(email: string){
    if (this.email == '')
      return true;

    return validateEmail(email);
  }

  validatePassword(senha: string){
    if (senha == '')
      return true;

    return validatePassword(senha);
  }

  sendPreRegistration(){

    this.spinner.show();

    var request = {
      cpf: this.cpf,
      fullName: this.name,
      cnpj: this.cnpj,
      companyName: this.companyName,
      clinicRoleId: this.clinicRoleId,
      specialtyId: this.specialtyId,
      crm: this.crm,
      crmState: this.crmState,
      email: this.email,
      phone: this.phone
    }

    this.commonService.sendPreRegistration(request)
      .pipe(take(1))
      .subscribe(response => {
        this.spinner.hide();
        this.registrationTabs.tabs[1].disabled = false;   
        this.registrationTabs.tabs[1].active = true;
      });
  }

  backToPersonalData(){
    this.registrationTabs.tabs[0].disabled = false;   
    this.registrationTabs.tabs[0].active = true;
    
    this.registrationTabs.tabs.map(tab => {
      tab.disabled = true;
    });
  }

  backToChoosePlan(){
    this.registrationTabs.tabs[1].disabled = false;   
    this.registrationTabs.tabs[1].active = true;
    
    this.registrationTabs.tabs.map(tab => {
      tab.disabled = true;
    });
  }

  selectPlan(plan: string){
    this.selectedPlan = this.planList.filter(item => {
      if (item.name == plan)
        return item;
    })[0];

    this.selectedPrice = (this.typePlan == 'mensal' ? this.selectedPlan.monthValue : this.selectedPlan.yearValue);
  }

  selectTypePlan(type: string){
    this.typePlan = type;
    this.selectedPrice = (this.typePlan == 'mensal' ? this.selectedPlan.monthValue : this.selectedPlan.yearValue);
  }

  sendToPayment(){
    this.registrationTabs.tabs[2].disabled = false;   
    this.registrationTabs.tabs[2].active = true;
    
    this.registrationTabs.tabs.map(tab => {
      tab.disabled = true;
    });

    this.setupMercadoPago();
  }

  validatePaymentForm(){
    let acceptanceTerm = document.getElementById('acceptanceTerm') as HTMLInputElement;
    let cardholderName = document.getElementById('form-checkout__cardholderName') as HTMLInputElement;
    let issuer = document.getElementById('form-checkout__issuer') as HTMLInputElement;
    let installments = document.getElementById('form-checkout__installments') as HTMLInputElement;
    let identificationNumber = document.getElementById('form-checkout__identificationNumber') as HTMLInputElement;

    if (!acceptanceTerm.checked)
      return false;

    if (this.selectedPrice==0)
      return true;
    
    if (cardholderName.value == '')
      return false;

    if (issuer.value == '')
      return false;

    if (installments.value == '')
      return false;

    if (identificationNumber.value == '')
      return false;

    return true;
  }

  onSelectIdentification(event: any){
    console.log(event.target.value);

    if (event.target.value === 'CPF') {
      this.documentMask = '00000000000';
    } else if (event.target.value === 'CNPJ') {
      this.documentMask = '00000000000000';
    } else {
      this.documentMask = '000.000.000-00';
    }
  }

  acceptance(){
    this.validatePaymentForm();
  }

  proccessPayment(payment: any){
    if (this.isProccessed)
      return;

    this.spinner.show();

    let cardholderName = document.getElementById('form-checkout__cardholderName') as HTMLInputElement;
    
    var request = {
      personalData: {
        cpf: this.cpf,
        fullName: this.name,
        cnpj: this.cnpj,
        companyName: this.companyName,
        clinicRoleId: this.clinicRoleId,
        specialtyId: this.specialtyId,
        crm: this.crm,
        crmState: this.crmState,
        email: this.email,
        phone: this.phone,
        password: this.password
      },
      payment: null,
      plan: {
        planVersionId: this.selectedPlan.id,
        contractTypeId: this.typePlan=='mensal' ? 1 : 2,
        discountCoupon: '',
        price: this.typePlan=='mensal' ? this.selectedPlan.monthValue : this.selectedPlan.yearValue
      }
    }

    if (payment!=null){
      request.payment = {
        token: payment.token,
        issuerId: payment.issuer_id,
        paymentMethodId: payment.payment_method_id,
        transactionAmount: payment.transaction_amount,
        installments: payment.installments,
        description: payment.description,
        payer: {
              email: payment.payer.email,
              firstName: cardholderName.value.split(' ')[0],
              lastName: cardholderName.value.split(' ')[cardholderName.value.split(' ').length-1],
              identification: {
                type: payment.payer.identification.type,
                number: payment.payer.identification.number,
              },
        }        
      };
    }
    this.isProccessed = true;
    this.commonService.register(request)
      .pipe(take(1))
      .subscribe(response => {
        this.spinner.hide();
        this.router.navigate(['']);
        Swal.fire(
          'Parabéns!',
          'Cadastro realizado com sucesso.',
          'success'
        );
        
        

      }, err => {
        Swal.fire(
          'Ops!',
          'Ocorreu um erro ao realizar seu cadastro.',
          'error'
        );
    });

  }

  showSpinner(){
    this.spinner.show();
  }

}
