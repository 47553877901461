import { HttpRequest } from '@angular/common/http';
import { DoctorService } from './../../../service/doctor.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CameraComponent } from 'src/app/components/camera/camera.component';
import { rotateImage } from "../../../util/util.js";
import { movePageToTop, isIOS } from "../../../util/util.js";
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { states } from 'src/app/models/const';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-profile-document',
  templateUrl: './profile-document.component.html',
  styleUrls: ['./profile-document.component.scss']
})
export class ProfileDocumentComponent implements OnInit {

  user: any;
  doctor: any;
  states: any[];

  crmForm: FormGroup;
  
  isCameraOpened: boolean = false;
  uploadSuccess: boolean = false;

  originalPreview: string;
  previewUrl: string;

  imagesDocument: any[] = [];
 
  degrees: number = 0;

  @ViewChild('cameraComponent', {static: false}) cameraComponent: CameraComponent;

  constructor(
    private formBuilder: FormBuilder,
    private doctorService: DoctorService,
    private spinner: NgxSpinnerService,
    public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.states = states;

    this.crmForm = this.formBuilder.group({
      crm: ['', Validators.required],
      state: ['', Validators.required]
    });

  }
  
  get f() { return this.crmForm.controls; }

  openCamera(type: string){
    this.isCameraOpened = true;
    setTimeout(()=>{
      this.cameraComponent.onTakePicture.subscribe(async(data) =>{
        const { imgBase64, orientation, fileName } = data;        

        this.previewUrl = imgBase64;
        this.originalPreview = this.previewUrl;
        this.isCameraOpened = false;
        
        if(isIOS()){          
          if(orientation === 8){
            this.degrees = 90;
            this.previewUrl = await rotateImage(this.originalPreview,this.degrees);
          }
          else if(orientation === 6){
            this.degrees = 270;
            this.previewUrl = await rotateImage(this.originalPreview, this.degrees);
          }
        }

        this.imagesDocument.push({
          file: this.previewUrl,
          fileName: fileName,
          type: type
        });

      });
      this.cameraComponent.openCamera();
    },1);

  }

  removeImages(){
    this.imagesDocument = [];
  }

  sendImage(){

    this.spinner.show();

    var images = this.imagesDocument.map(image => {
      return {
        fileBase64: image.file.replace(/^data:image\/\w+;base64,/, ''),
        fileName: image.fileName
      }
    });

    var request = {
      number: this.crmForm.controls.crm.value,
      state: this.crmForm.controls.state.value,
      imageDocumentFront: images[0],
      imageDocumentBack: images[1]
    }

    this.doctorService.addDocument(request)
      .pipe(take(1))
      .subscribe(response => {        
        
        this.spinner.hide();
        this.bsModalRef.hide();

        this.user = JSON.parse(localStorage.getItem('currentUser'));
        this.user.doctor.crm = response.data.crm;    
        localStorage.removeItem('currentUser');
        localStorage.setItem('currentUser', JSON.stringify(this.user));

          Swal.fire(
            'Parabés!',
            'Documento enviado com sucesso.',
            'success'
          ); 
      },
      err => {

        this.spinner.hide(); 

        Swal.fire(
          'Ops!',
          err,
          'error'
        );
      });
    
  }

}
