import { Component, OnInit } from '@angular/core';
import { BsDatepickerConfig, BsLocaleService, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { listLocales } from 'ngx-bootstrap/chronos';
import { NgxSpinnerService } from 'ngx-spinner';
import { ScheduleService } from 'src/app/service/schedule.service';
import Swal from 'sweetalert2';
import { take } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-doctor-open-schedule',
  templateUrl: './doctor-open-schedule.component.html',
  styleUrls: ['./doctor-open-schedule.component.scss']
})
export class DoctorOpenScheduleComponent implements OnInit {
  user: any;
  locale = 'pt-BR';  
  locales = listLocales();
  title: string;
  dateSelected: any[] = [];
  selectedClass: DatepickerDateCustomClasses[];
  bsConfig: Partial<BsDatepickerConfig>;
  minDate = new Date();
  scheduleRequest: any = {
    doctorId: 0,
    hospitalId: 0,
    startHour: '',
    endHour: '',
    startPauseHour: '',
    endPauseHour: '',
    duration: 0,
    dates: []
  }
  

  constructor(
    public bsModalRef: BsModalRef,
    private localeService: BsLocaleService,
    private spinner: NgxSpinnerService,
    private scheduleService: ScheduleService
  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    if (this.user.doctor)
      this.scheduleRequest.doctorId = this.user.doctor.id;

    this.bsConfig = Object.assign({}, { 
      containerClass: 'theme-blue',
      minDate: this.minDate 
    });
    this.localeService.use(this.locale);
  }

  getDateItem(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  onValueChange(event) {
    if (event.length === undefined) { 
      const date = this.getDateItem(event);
      
      const index = this.dateSelected.findIndex(item => {
        const testDate = this.getDateItem(item);
        return testDate === date;
      });

      if (index < 0) {
        this.dateSelected.push(event);
      }
      else {
        this.dateSelected.splice(index, 1);
      }
    }

    if (this.dateSelected.length > 0) {
      this.selectedClass = this.dateSelected.map(date => {
        return {
          date, 
          classes: ['custom-selected-date']
        }
      });
    }
  }

  getHospital(value: number){
    this.scheduleRequest.hospitalId = value;
  }

  getDoctor(value: number){
    this.scheduleRequest.doctorId = value;
  }

  openSchedule(){
    this.spinner.show();

    this.scheduleRequest.dates = this.dateSelected;
    this.scheduleRequest.dates = this.dateSelected.map(item => {
      return item = moment(item).startOf('day').format()
    })

    this.scheduleService.openSchedule(this.scheduleRequest)
    .pipe(take(1))
    .subscribe(response => {

      this.spinner.hide();   
      if(response.data){
        this.bsModalRef.hide();
        Swal.fire(
          'Sucesso!',
          'Agenda aberta com sucesso.',
          'success'
        );
      }
      else{
        Swal.fire(
          'Atenção!',
          response.validationErrors[0].errorMessage,
          'warning'
        );
      } 
    }, () => {
      this.spinner.hide();
      Swal.fire(
        'Ops!',
        'Ocorreu um erro ao abrir a agenda.',
        'error'
      );
    });
  }

  verify(){
    if (this.dateSelected.length==0)
      return false;
    
    if (this.scheduleRequest.hospitalId == 0)
      return false;

    if (this.scheduleRequest.startHour == '')
      return false;

    if (this.scheduleRequest.endHour == '')
      return false;
    
    if (this.scheduleRequest.duration <= 0)
      return false;
    
    return true;
  }

}
