import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import { UserService } from 'src/app/service/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  login: string;

  constructor(
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  send(){
    this.spinner.show();
    
    var request = {
      cpf: this.login
    }

    this.userService.sendForgotPasswordToken(request)
      .pipe(take(1)).subscribe(response => {
        this.spinner.hide();
        if (response.data){
          this.router.navigate(['/login']);
          Swal.fire(
            "Atenção!",
            "Enviamos uma mensagem para o e-mail cadastrado. Clique no link e cadastre uma nova senha.",
            "info"
          )
        }
        else{
          Swal.fire(
            "Atenção!",
            response.validationErrors[0].errorMessage,
            "warning"
          )
        }
      }, () => {
        this.spinner.hide();
        Swal.fire(
          "Ops!",
          "Erro ao alterar a senha.",
          "error"
        )
      })
  }
}
