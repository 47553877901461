import { NgxSpinnerService } from 'ngx-spinner';
import { ShiftService } from 'src/app/service/shift.service';
import { shiftStatus } from './../../../models/const';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shift-details',
  templateUrl: './shift-details.component.html',
  styleUrls: ['./shift-details.component.scss']
})
export class ShiftDetailsComponent implements OnInit {

  shift: any;
  applications: any[] = [];
  googleMaps: string = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyCvxNBlM7PQg2S5-GzbSnApbhrkeu6cB_4&q=';
  
  constructor(
    public bsModalRef: BsModalRef,
    private shiftService: ShiftService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getAllApplies();
    this.googleMaps =  this.googleMaps + this.shift.hospital.name + ',' + this.shift.hospital.city;
    console.log(this.shift)
  }

  getColor(id: number){
    return shiftStatus.filter(item => {
      return item.id == id;
    });
  }

  getAllApplies(){

    this.spinner.show();

    this.shiftService.getAllApplies(this.shift.id)
    .pipe(take(1)).subscribe(response => {  
      if (response.data){
        this.applications = response.data
        console.log(this.applications);
      }
      console.log();
      this.spinner.hide();
    });
  }

  getCrm(crmList: any[]){
    var crm = crmList.filter(item => {
      return item.state == this.shift.hospital.state;
    })[0];

    if (crm == undefined){
      crm = crmList[0];
    }

    return crm.number + '/' + crm.state;
  }

  approve(doctorId: number){
    this.bsModalRef.hide();
    this.spinner.show();

    this.shiftService.approve(this.shift.id, doctorId)
    .pipe(take(1)).subscribe(response => {  
      if (response.data){
        Swal.fire(
          'Parabéns!',
          'Médico selecionado com sucesso.',
          'success'
        );
      }
      else {
        Swal.fire(
          'Atenção!',
          response.validationErrors[0].errorMessage
          ,
          'error'
        );
      }
      this.spinner.hide();
    });
  }

  cancel(){
    Swal.fire({
      title: 'Cancelar Plantão',
      icon: 'question',
      text: 'Deseja realmente cancelar o plantão?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      cancelButtonText: 'Não',
      confirmButtonColor: '#36b9cc',
      confirmButtonText: 'Sim',
    }).then((result) => {
      if (result.isConfirmed) {
        this.bsModalRef.hide();
        this.spinner.show();

        this.shiftService.cancel(this.shift.token)
        .pipe(take(1)).subscribe(response => {  
          if (response.data){
            Swal.fire({
              title: 'Parabéns!',
              icon: 'success',
              text: 'Plantão cancelado com sucesso.',
              showCloseButton: true,
              confirmButtonColor: '#36b9cc',
              confirmButtonText: 'Fechar',
            })
          }
          this.spinner.hide();
        });
      }
      
    })
  }

}
