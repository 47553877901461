import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const url = environment.endpoint;

@Injectable({
  providedIn: 'root'
})
export class DoctorService {

  constructor(private http: HttpClient) { }

  getAllByCompany(): Observable<any> {
    return this.http.get<any>(url + "/doctor/company", httpOptions);
  }

  add(doctor: any): Observable<any> {
    return this.http.post<any>(url + "/doctor", doctor, httpOptions);
  }

  update(doctor: any): Observable<any> {
    return this.http.put<any>(url + "/doctor", doctor, httpOptions);
  }

  updateImage(image: any): Observable<any> {
    return this.http.put<any>(url + "/doctor/image", image, httpOptions);
  }

  updateDocument(document: any): Observable<any> {
    return this.http.put<any>(url + "/doctor/document", document, httpOptions);
  }

  verify(token: string): Observable<any> {
    return this.http.post<any>(url + "/doctor/" + token + "/verify", httpOptions);
  }

  addDocument(document: any): Observable<any> {
    return this.http.post<any>(url + "/doctor/document", document, httpOptions);
  }

  getById(id: number): Observable<any> {
    return this.http.get<any>(url + "/doctor/" + id, httpOptions);
  }

  getAllByCompanyPaged(page: number, pageSize: number, search: string): Observable<any> {
    return this.http.get<any>(url + "/doctor/company/paged?page=" + page + "&pageSize=" + pageSize + "&search=" + search, httpOptions);
  }

  getOpenSchedule(identify: string): Observable<any> {
    return this.http.get<any>(url + "/doctor/open-schedule?identify=" + identify, httpOptions);
  }
}
