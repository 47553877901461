import { addMonths } from 'date-fns';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { ShiftSearchFilterComponent } from '../shift-search-filter/shift-search-filter.component';
import { ShiftService } from 'src/app/service/shift.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { shiftStatus } from 'src/app/models/const';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shift-search',
  templateUrl: './shift-search.component.html',
  styleUrls: ['./shift-search.component.scss']
})
export class ShiftSearchComponent implements OnInit {

  bsModalRef: BsModalRef;
  user: any;

  filter: any = {
    city: 'São Paulo',
    cityDescription: 'São Paulo - SP',
    startDate: '',
    endDate: '',
    specialtyId: 0,
    value: 300,
    highValue: 3000
  }

  startDate: string;
  endDate: string;

  shifts: any[] = [];
  shiftsFiltered: any[] = [];

  constructor(
    private commonService: CommonService,
    private modalService: BsModalService,
    private shiftService: ShiftService,
    private spinner: NgxSpinnerService,
    ) { }

  ngOnInit() {
    this.commonService.changeResult(true);

    this.startDate = moment().format('YYYY-MM-DD');
    this.filter.startDate = moment().format('YYYY-MM-DD');
    
    this.endDate =  moment(this.filter.startDate).add(1, 'month').format('YYYY-MM-DD');
    this.filter.endDate =  moment(this.filter.startDate).add(1, 'month').format('YYYY-MM-DD');

    this.getAllToApply();

    this.user = JSON.parse(localStorage.getItem('currentUser'));
    
  }

  getAllToApply(){

    this.spinner.show();    
    
    this.shiftService.getAllToApply(this.filter.city, this.filter.startDate, this.filter.endDate)
    .pipe(take(1)).subscribe(response => { 
      this.spinner.hide();      
      if (response.data){
        this.shifts = response.data;

        this.shiftsFiltered = this.shifts.filter(item =>{
          return (
            (item.value >= this.filter.value && item.value <= this.filter.highValue) &&
            (this.filter.specialtyId == 0 || item.specialtyId == this.filter.specialtyId)
          );
        });
        console.log(this.shiftsFiltered);
      }
      else{
        this.shiftsFiltered = [];
      }
    });
  }

  openFilter(): void {
    
    var initialState = {   
      filter: this.filter   
    };

    this.bsModalRef = this.modalService.show(
      ShiftSearchFilterComponent, Object.assign({}, { 
        class: 'modal-lg',
        initialState: initialState
      }
    ));

    this.bsModalRef.content.filterResponse.subscribe((response) => {
      this.getAllToApply();
    });
  };

  getColor(id: number){
    return shiftStatus.filter(item => {
      return item.id == id;
    });
  }

  cleanDateFilter(){
    this.filter.startDate = moment().format('YYYY-MM-DD');
    this.filter.endDate =  moment(this.filter.startDate).add(1, 'month').format('YYYY-MM-DD');
    this.getAllToApply();
  }

  cleanSpecialtyFilter(){
    this.filter.specialtyId = 0;
    this.getAllToApply();
  }

  cleanValueFilter(){
    this.filter.value = 300;
    this.filter.highValue = 3000;
    this.getAllToApply();
  }

  applyToShift(shift: any){
    Swal.fire({
      title: 'Deseja candidatar-se ao plantão?',
      html: "<small>Ao se candidatar, você deverá aguardar a aprovação do administrador do plantão.</small>",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#36b9cc',
      //cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {

        this.spinner.show();

        this.shiftService.apply(shift.id)
        .pipe(take(1)).subscribe(response => { 

          this.spinner.hide();    

          if (response.data){
    
            this.shiftsFiltered = this.shifts.filter(item =>{
              return (
                (item.value >= this.filter.value && item.value <= this.filter.highValue) &&
                (this.filter.specialtyId == 0 || item.specialtyId == this.filter.specialtyId)
              );
            });

            Swal.fire(
              'Sucesso!',
              'Você se candidatou ao plantão, agora é só aguardar.',
              'success'
            ).then(() => {
              this.getAllToApply();
            });

            return;
          }

          if(response.validationErrors){
            Swal.fire(
              'Erro!',
              response.validationErrors[0].errorMessage,
              'error'
            );
            return;
          } 
        });
        
      }
    })
  }

  alreadyApply(shift: any){
    
    if (shift.shiftApplies != null){
      var apply = shift.shiftApplies.filter(item => {
        return item.doctorId == this.user.doctor.id;
      });

      if (apply)
        return true;
      else
        return false;
    }
    else {
      return false;
    }
  }

}
