import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable, Observer, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { DoctorBookService } from 'src/app/service/doctor-book.service';

@Component({
  selector: 'app-search-disease',
  templateUrl: './search-disease.component.html',
  styleUrls: ['./search-disease.component.scss']
})
export class SearchDiseaseComponent implements OnInit {

  diseases: any[] = [];
  disease: string;
  dataSource: Observable<any>;
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;
  errorMessage: string;

  @Input() diseaseSelected: string;
  @Output('diseaseName') diseaseName = new EventEmitter<string>();
  
  constructor(
    private doctorBookService: DoctorBookService,
    private titlecasePipe:TitleCasePipe
  ) { 
    
  }

  ngOnInit(): void {
    this.dataSource = new Observable((observer: Observer<string>) => {
      observer.next(this.disease);
    }).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.doctorBookService.getDisease(this.disease).pipe(
            map((response: any) => response && response.data.results || []),
            tap(() => {}, err => {
              // in case of http error
              this.errorMessage = err && err.message || 'Something goes wrong';
            })
          );
        }
 
        return of([]);
      })
    );

    this.setDiseaseDefault();
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  onChange(){
    if(this.disease == "")
      this.diseaseName.emit('');
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.diseaseName.emit(e.item.description);
  }

  typeaheadOnBlur(): void {
    this.diseaseName.emit(this.disease);
  }

  setDiseaseDefault(){
    if (this.diseaseSelected != undefined){      
      this.disease = this.diseaseSelected;
    }
  }

}
