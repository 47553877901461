import { TitleCasePipe } from '@angular/common';
import { DoctorService } from './../../service/doctor.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/public_api';

@Component({
  selector: 'app-search-doctor-company',
  templateUrl: './search-doctor-company.component.html',
  styleUrls: ['./search-doctor-company.component.scss']
})
export class SearchDoctorCompanyComponent implements OnInit {

  doctors: any[] = [];
  doctor: string;
  dataSource: Observable<any>;
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;

  @Input() doctorSelected: number;
  @Output('doctorId') doctorId = new EventEmitter<number>();

  constructor(
    private doctorService: DoctorService,
    private titlecasePipe:TitleCasePipe
  ) {
    this.dataSource = Observable.create((observer: any) => {
      observer.next(this.doctor);
    })
      .pipe(
        mergeMap((token: string) => this.getAsObservable(token))
      );
   }

  ngOnInit() {
    this.getAllDoctors();
  }

  getAllDoctors(){
    
    this.doctorService.getAllByCompany()
    .pipe(take(1)).subscribe(response => {  
      this.doctors = response.data;
      this.doctors.filter(item => {
        item.name = this.titlecasePipe.transform(item.name);
      });
      this.setDefault();
    });
  }

  getAsObservable(token: string): Observable<any> {
    const query = new RegExp(token, 'i');
 
    return of(
      this.doctors.filter((item: any) => {
        return query.test(item.name);
      })
    );
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.doctorId.emit(e.item.id); 
  }

  onChange(){
    if(this.doctor == "")
      this.doctorId.emit(0);
  }

  setDefault(){
    if (this.doctorSelected != undefined){      
      this.doctors.filter((item: any) => {
        if (item.id == this.doctorSelected)
          this.doctor = item.name;
      })
      this.doctorId.emit(this.doctorSelected);
    }
  }

}
