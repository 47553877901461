// https://www.npmjs.com/package/compress.js

import Base64 from './src/core/base64.js'
import Converter from './src/core/converter.js'
import File from './src/core/file.js'
import Image from './src/core/image.js'
import Photo from './src/core/photo.js'
import Rotate from './src/core/rotate.js'
import { isIOS } from '../util'


class Compress {
  attach (el, options) {
    return new Promise((resolve, reject) => {
      const input = document.querySelector(el)
      input.setAttribute('accept', 'image/*')
      input.addEventListener('change', (evt) => {
        const output = this.compress([...evt.target.files], options)
        resolve(output)
      }, false)
    })
  }

  compressFile (file, options, rotate = true) {

    // Create a new photo object
    const photo = new Photo(options)
    photo.start = window.performance.now()
    photo.alt = file.name
    photo.ext = file.type
    photo.startSize = file.size
    if(rotate){
      return Rotate.orientation(file)
      .then((orientation) => {
        // photo.orientation = 1;
        if(!isIOS()){
          if(orientation === 6 || orientation === 8){
            photo.orientation = 1
          }
          else{
            photo.orientation = orientation
          }
        }
        else{
          photo.orientation = orientation
        }
        return File.load(file)
      })
      .then(this.compressImage(photo))
    }
    else{
      return File.load(file)
      .then(this.compressImage(photo))
   }

  }

  compressImage (photo) {
    return (src) => {
      return Image.load(src).then((img) => {
        // Store the initial dimensions
        photo.startWidth = img.naturalWidth
        photo.startHeight = img.naturalHeight
        // Resize the image
        if (photo.resize) {
          const { width, height } = Image.resize(photo.maxWidth, photo.maxHeight)(img.naturalWidth, img.naturalHeight)
          photo.endWidth = width
          photo.endHeight = height
        } else {
          photo.endWidth = img.naturalWidth
          photo.endHeight = img.naturalHeight
        }
        return Converter.imageToCanvas(photo.endWidth, photo.endHeight, photo.orientation)(img)
      })
      .then((canvas) => {
        photo.iterations = 1
        // Base64.mime(Converter.canvasToBase64(canvas))
        photo.base64prefix = Base64.prefix(photo.ext)
        return this.loopCompression(canvas, photo.startSize, photo.quality, photo.size, photo.minQuality, photo.iterations)
      })
      .then((base64) => {
        photo.finalSize = Base64.size(base64)
        return Base64.data(base64)
      })
      .then((data) => {
        photo.end = window.performance.now()
        const difference = photo.end - photo.start // in ms

        return {
          data: data,
          prefix: photo.base64prefix,
          elapsedTimeInSeconds: difference / 1000, // in seconds
          alt: photo.alt,
          initialSizeInMb: Converter.size(photo.startSize).MB,
          endSizeInMb: Converter.size(photo.finalSize).MB,
          ext: photo.ext,
          quality: photo.quality,
          endWidthInPx: photo.endWidth,
          endHeightInPx: photo.endHeight,
          initialWidthInPx: photo.startWidth,
          initialHeightInPx: photo.startHeight,
          sizeReducedInPercent: (photo.startSize - photo.finalSize) / photo.startSize * 100,
          iterations: photo.iterations,
          orientation: photo.orientation
        }
      })
    }
  }

  loopCompression (canvas, size, quality = 1, targetSize, targetQuality = 1, iterations) {
    const base64str = Converter.canvasToBase64(canvas, quality)
    const newSize = Base64.size(base64str)
    // const base64str = convertCanvasToBase64(src)
    // const size = getFileSize(base64str);
    iterations += 1
    // add in iteration count
    if (newSize > targetSize) {
      return loopCompression(canvas, newSize, quality - 0.1, targetSize, targetQuality, iterations)
    }

    if (quality > targetQuality) {
      return loopCompression(canvas, newSize, quality - 0.1, targetSize, targetQuality, iterations)
    }

    if (quality < 0.5) {
      return base64str
    }
    return base64str
  }

  compress (files, options) {
    return Promise.all(files.map((file) => {
      return this.compressFile(file, options)
    }))
  }

  dataURItoBlob(dataURI) {
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
}

  compressFromBase64(base64File, options){
    let file = this.dataURItoBlob(base64File);
    return this.compressFile(file,options, false);
  }

  static convertBase64ToFile (base64, mime) {
    return Converter.base64ToFile(base64, mime)
  }
}

// Supported input formats
// image/png, image/jpeg, image/jpg, image/gif, image/bmp, image/tiff, image/x-icon,  image/svg+xml, image/webp, image/xxx
// image/png, image/jpeg, image/webp
export default Compress;
