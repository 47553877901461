import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const url = environment.endpoint;

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private sidebarToogled = new BehaviorSubject({});
  isSidebarToogled = this.sidebarToogled.asObservable();

  changeResult(toogled: boolean) {
    this.sidebarToogled.next(toogled);
  }

  constructor(
    private http: HttpClient
    ) { 
    this.sidebarToogled.next(true);
  }

  getAllHospitals(): Observable<any> {
    return this.http.get<any>(url + "/hospital", httpOptions);
  }

  getAllSpecialties(): Observable<any> {
    return this.http.get<any>(url + "/specialty", httpOptions);
  }

  getDefaultHospital(): Observable<any> {
    return this.http.get<any>(url + "/hospital/default", httpOptions);
  }

  getAllCities(): Observable<any> {
    return this.http.get<any>(url + "/hospital/cities", httpOptions);
  }

  getQRCodeCompany(): Observable<any> {
    return this.http.get<any>(url + "/company/qrcode", httpOptions);
  }

  getAddress(postalCode: string): Observable<any> {
    return this.http.get<any>("https://viacep.com.br/ws/" + postalCode +"/json/")
  }

  getAllHealthInsurance(): Observable<any> {
    return this.http.get<any>(url + "/health-insurance", httpOptions);
  }

  sendPreRegistration(request: any): Observable<any> {
    return this.http.post<any>(url + "/register/pre-registration", request, httpOptions);
  }

  register(request: any): Observable<any> {
    return this.http.post<any>(url + "/register", request, httpOptions);
  }

  editHospital(request: any): Observable<any> {
    return this.http.put<any>(url + "/hospital", request, httpOptions);
  }
}
