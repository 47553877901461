import { DoctorService } from './../../../service/doctor.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CameraComponent } from 'src/app/components/camera/camera.component';
import { rotateImage } from "../../../util/util.js";
import { movePageToTop, isIOS } from "../../../util/util.js";
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-profile-selfie',
  templateUrl: './profile-selfie.component.html',
  styleUrls: ['./profile-selfie.component.scss']
})
export class ProfileSelfieComponent implements OnInit {

  constructor(
    private doctorService: DoctorService,
    private spinner: NgxSpinnerService,
    public bsModalRef: BsModalRef) { }

  doctor: any;
  user: any;

  isCameraOpened: boolean = false;
  previewUrl:any = null;
  originalPreview: any = null;
  degrees: number = 0;
  uploadSuccess: boolean = false;
  fileName: string;

  @ViewChild('cameraComponent', {static: false}) cameraComponent: CameraComponent;

  ngOnInit() {
  }

  openCamera(){
    this.isCameraOpened = true;
    setTimeout(()=>{
      this.cameraComponent.onTakePicture.subscribe(async(data) =>{
        const { imgBase64, orientation, fileName } = data;
       this.fileName = fileName;
        this.previewUrl = imgBase64;
        this.originalPreview = this.previewUrl;
        this.isCameraOpened = false;
       
        if(isIOS()){          
          if(orientation === 8){
            this.degrees = 90;
            this.previewUrl = await rotateImage(this.originalPreview,this.degrees);
          }
          else if(orientation === 6){
            this.degrees = 270;
            this.previewUrl = await rotateImage(this.originalPreview, this.degrees);
          }
        }

      });
      this.cameraComponent.openCamera();
    },1);

  }

  async handleRotateImage(){
    this.degrees+=90;
    if (this.degrees==360)
      this.degrees=0;
    this.previewUrl = await rotateImage(this.originalPreview, this.degrees);
  }

  sendImage(){

    this.spinner.show();

    var request = {
      fileBase64: this.previewUrl.replace(/^data:image\/\w+;base64,/, ''),
      fileName: this.fileName
    }

    this.doctorService.updateImage(request)
      .pipe(take(1))
      .subscribe(response => {        
        
        this.spinner.hide();
        this.bsModalRef.hide();

        this.user = JSON.parse(localStorage.getItem('currentUser'));
        this.user.doctor.image = response.data.image;    
        localStorage.removeItem('currentUser');
        localStorage.setItem('currentUser', JSON.stringify(this.user));

          Swal.fire(
            'Parabés!',
            'Selfie enviada com sucesso.',
            'success'
          ); 
      },
      err => {

        this.spinner.hide(); 

        Swal.fire(
          'Ops!',
          err,
          'error'
        );
      });
    
  }

  cancelImage(){
    this.previewUrl = null;
    this.originalPreview = null;
    this.openCamera();
  }

}
