export const colors: any = {
  danger: {
    primary: '#f5c6cb',
    secondary: '#f8d7da',
    title: '#721c24'    
  },
  info: {
    primary: '#bee5eb',
    secondary: '#d1ecf1'
  },
  warning: {
    primary: '#ffeeba',
    secondary: '#fff3cd'
  },
  secondary: {
    primary: '#d6d8db',
    secondary: '#e2e3e5'
  },
  success: {
    primary: '#c3e6cb',
    secondary: '#d4edda'
  },
  primary: {
    primary: '#b8daff',
    secondary: '#cce5ff'
  }
  };

export const shiftStatus: any[] = [
    {
      id: 1,
      nome: 'Ofertado',
      color: colors.secondary
    },
    {
      id: 2,
      nome: 'Liberado',
      color: colors.info
    },
    {
      id: 3,
      nome: 'Confirmado',
      color: colors.success
    },
    {
      id: 4,
      nome: 'Não Confirmado',
      color: colors.warning
    },
    {
      id: 5,
      nome: 'Cancelado',
      color: colors.danger
    }
  ];

export const administratorMenu: any = {
  mainMenu :{
    home: {
      icon: 'fas fa-fw fa-tachometer-alt',
      url: '/home',
      description: 'Dashboard'
    },
    heading: [
      {
        description: 'Administrar',
        link: [
          {
            icon: 'fas fa-fw fa-tachometer-alt',
            url: '/home',
            description: 'Dashboard'
          }, 
          {
            icon: 'fas fa-fw fa-calendar-check',
            url: '/plantao',
            description: 'Plantões'
          },          
          {
            icon: 'fas fa-fw fa-calendar-check',
            url: '/agenda',
            description: 'Meus Plantões'
          },       
          {
            icon: 'fas fa-fw fa-search',
            url: '/plantao/buscar',
            description: 'Buscar Plantões'
          },
          {
            icon: 'fas fa-fw fa-user-md',
            url: '/medico',
            description: 'Médicos'
          },
          {
            icon: 'fas fa-fw fa-calendar',
            url: '/agendamento',
            description: 'Agendamento'
          },
          {
            icon: 'fas fa-fw fa-file-medical',
            url: '/paciente',
            description: 'Prontuário'
          },
          {
            icon: 'fas fa-fw fa-stethoscope',
            url: '/doctor-book/diagnostico',
            description: 'Hipótese Diagnóstica'
          },
          {
            icon: 'fas fa-fw fa-microscope',
            url: '/doctor-book/tratamento',
            description: 'Tratamentos'
          }
          // {
          //   icon: 'fas fa-fw fa-check-square',
          //   url: '/plantao/verificar',
          //   description: 'Verificar Plantões'
          // }
        ]
      }
    ]
  },
  topMenu: []
  
};

export const doctorMenu: any = {
  mainMenu : {
    heading: [
      {
        description: 'Pesquisar',
        link: [
          {
            icon: 'fas fa-fw fa-tachometer-alt',
            url: '/home',
            description: 'Dashboard'
          }, 
          {
            icon: 'fas fa-fw fa-calendar-check',
            url: '/agenda',
            description: 'Meus Plantões'
          },
          {
            icon: 'fas fa-fw fa-search',
            url: '/plantao/buscar',
            description: 'Buscar Plantões'
          },
          {
            icon: 'fas fa-fw fa-calendar',
            url: '/agendamento',
            description: 'Agendamento'
          },  
          {
            icon: 'fas fa-fw fa-file-medical',
            url: '/paciente',
            description: 'Prontuário'
          },  
          {
            icon: 'fas fa-fw fa-stethoscope',
            url: '/doctor-book/diagnostico',
            description: 'Hipótese Diagnóstica'
          },
          {
            icon: 'fas fa-fw fa-microscope',
            url: '/doctor-book/tratamento',
            description: 'Tratamentos'
          }
        ]
      }
    ]
  },
  topMenu : [
    {
      icon: 'fas fa-user fa-sm fa-fw mr-2 text-gray-400',
      url: '/perfil',
      description: 'Meu Perfil'
    }
  ]
};

export const states: any[] = [
  { name: 'AC' },
  { name: 'AL' },
  { name: 'AP' },
  { name: 'AM' },
  { name: 'BA' },
  { name: 'CE' },
  { name: 'ES' },
  { name: 'GO' },
  { name: 'MA' },
  { name: 'MT' },
  { name: 'MS' },
  { name: 'MG' },
  { name: 'PA' },
  { name: 'PB' },
  { name: 'PR' },
  { name: 'PE' },
  { name: 'PI' },
  { name: 'RJ' },
  { name: 'RN' },
  { name: 'RS' },
  { name: 'RO' },
  { name: 'RR' },
  { name: 'SC' },
  { name: 'SP' },
  { name: 'SE' },
  { name: 'TO' },
  { name: 'DF' }
];

export const scheduleStatus: any[] = [
  {
    id: 1,
    nome: 'Em Aberto',
    color: colors.secondary
  },
  {
    id: 2,
    nome: 'Agendado',
    color: colors.info
  },
  {
    id: 3,
    nome: 'Confirmado',
    color: colors.success
  },
  {
    id: 4,
    nome: 'Finalizado',
    color: colors.secondary
  },
  {
    id: 5,
    nome: 'Bloqueado',
    color: colors.warning
  }
];