import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import { UserService } from 'src/app/service/user.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-change-company',
  templateUrl: './change-company.component.html',
  styleUrls: ['./change-company.component.scss']
})
export class ChangeCompanyComponent implements OnInit {

  companies: any[] = [];
  comanySelectedId: any;

  constructor(
    public bsModalRef: BsModalRef,
    private userService: UserService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
  }

  changeCompany(companyId: any){
    this.spinner.show();
    this.userService.changeCompany(companyId)
      .pipe(take(1))
      .subscribe(() => {
        this.spinner.hide();
        this.bsModalRef.hide();
        Swal.fire(
          'Parabéns!',
          'Empresa alterada com sucesso.',
          'success'
        );
      },
      () => {
        this.spinner.hide();
        Swal.fire(
          'Ops!',
          'Ocorreu um erro ao alterar a empresa.',
          'error'
        );
      });
  }

}
