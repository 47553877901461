import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import { CommonService } from 'src/app/service/common.service';
import { UserService } from 'src/app/service/user.service';
import Swal from 'sweetalert2'
import { CompanyHospitalFormComponent } from './company-hospital-form/company-hospital-form.component';
import { CompanyUserFormComponent } from './company-user-form/company-user-form.component';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  users: any[] = [];
  usersFiltred: any[] = [];
  hospital: any;
  bsModalRef: BsModalRef;
  bsModalUserRef: BsModalRef;

  constructor(
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private commonService: CommonService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.getUsers();
    this.getHospital();
  }

  getUsers(){
    this.spinner.show();
    this.userService.getUsers()
    .pipe(take(1)).subscribe(response => {  
      this.spinner.hide()
      if (response.data){
        this.users = response.data;
        this.usersFiltred = this.users;
      }
      this.spinner.hide();
    },() => {
      this.spinner.hide();
      Swal.fire(
        'Ops!',
        'Ocorreu um erro ao buscar os usuários.',
        'error'
      );
    });
  }

  getHospital(){
    this.commonService.getDefaultHospital()
    .pipe(take(1)).subscribe(response => {  
      this.spinner.hide();
      if (response.data){
        this.hospital = response.data;
        console.log(this.hospital);
      }
      this.spinner.hide();
    },() => {
      this.spinner.hide();
      Swal.fire(
        'Ops!',
        'Ocorreu um erro ao buscar a clínica.',
        'error'
      );
    });
  }

  filterByText(value: string){
    this.usersFiltred = this.users.filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
  }

  openHospitalForm(){
    var initialState = {    
      hospital: this.hospital
    };

    this.bsModalRef = this.modalService.show(
      CompanyHospitalFormComponent, Object.assign({}, { 
        class: 'modal-md',
        initialState: initialState,
        ignoreBackdropClick: true
      }
    ));

    this.modalService.onHidden
    .pipe(take(1))
    .subscribe(() => {      
      this.getHospital();
    })
  }

  openUserForm(){
    var initialState = {    
      hospital: this.hospital
    };

    this.bsModalUserRef = this.modalService.show(
      CompanyUserFormComponent, Object.assign({}, { 
        class: 'modal-md',
        initialState: initialState,
        ignoreBackdropClick: true
      }
    ));

    this.modalService.onHidden
    .pipe(take(1))
    .subscribe(() => {      
      this.getUsers();
    })
  }

}
