import { DoctorService } from './../../service/doctor.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { states } from 'src/app/models/const';
import { UserService } from 'src/app/service/user.service';
import { validatePassword } from "../../util/util.js";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  errorLogin: boolean = false;
  validationError: string;
  states: any[];
  token: string;
  user: any;
  
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService) { }

  ngOnInit() {

    this.token = this.route.snapshot.params.token;
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      cpf: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      crm: [''],
      state: [''],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm: ['', Validators.required]
    }, {validator: this.checkPasswords });

    this.states = states;
    
    this.getUserToken();
  }

  get f() { return this.registerForm.controls; }

  onSubmit(){

    this.submitted = true;
    this.errorLogin = false;
    this.validationError = '';
    this.spinner.show();

    if (this.registerForm.invalid) {
      this.spinner.hide();
      return;
    }

    var request = {
      id: this.user.id,
      name: this.registerForm.controls.name.value,
      email: this.registerForm.controls.email.value,
      phone: this.registerForm.controls.phone.value,
      cpf: this.registerForm.controls.cpf.value,
      crm: this.registerForm.controls.crm.value,
      crmState: this.registerForm.controls.state.value,
      password: this.registerForm.controls.password.value,
      token: this.token
    }

    this.userService.completeRegistration(request)
      .pipe(take(1))
      .subscribe(response => {

        this.spinner.hide();   
        if(response.data){
          this.router.navigate(['login']);
          Swal.fire(
            'Sucesso!',
            'Cadastro efetuado com sucesso.',
            'success'
          );
        }
        else{
          this.validationError = response.validationErrors[0].errorMessage;
        } 
      });

  }

  checkPasswords(group: FormGroup) {
    let pass = group.get('password').value;
    let confirmPass = group.get('confirm').value;

    return pass === confirmPass ? null : { notSame: true }     
  }

  validatePassword(group: FormGroup){
    let pass = group.get('password').value;
    if (pass == '')
      return true;

    return validatePassword(pass);
  }

  getUserToken(){
    this.spinner.show();
    this.userService.getUserToken(this.token)
      .pipe(take(1)).subscribe(response => {
        this.spinner.hide();
        if(response.data){          
          this.user = response.data;

          this.registerForm.patchValue({
            name: this.user.name,
            cpf: this.user.cpf,
            email: this.user.email,
            phone: this.user.phone
          });

        }
        else{
          Swal.fire(
            'Ops!',
            response.validationErrors[0].errorMessage,
            'error'
          );
        } 
      });
  }

}
