import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { validateCPF, validateEmail } from "../../../util/util.js";
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2'
import { UserService } from 'src/app/service/user.service.js';

@Component({
  selector: 'app-company-user-form',
  templateUrl: './company-user-form.component.html',
  styleUrls: ['./company-user-form.component.scss']
})
export class CompanyUserFormComponent implements OnInit {

  user:any = {
    name: '',
    cpf: '',
    phone: '',
    email: '',
    userProfileId: 0
  };

  constructor(
    public bsModalRef: BsModalRef,
    private spinner: NgxSpinnerService,
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  validate(){
    if(this.user.name == '')
      return false;
    
    if(this.user.cpf == '' || !this.validateCPF(this.user.cpf))
      return false;
    
    if(this.user.email == '' || !this.validateEmail(this.user.email))
      return false;

    if (this.user.phone == '')
      return false;

    if (this.user.userProfileId == 0)
      return false;

    return true;
  }

  validateCPF(cpf: string){
    if (this.user.cpf == '')
      return true;

    return validateCPF(cpf);
  }

  validateEmail(email: string){
    if (this.user.email == '')
      return true;

    return validateEmail(email);
  }

  save(){
    this.spinner.show();
    this.userService.addUser(this.user)
    .pipe(take(1)).subscribe(() => {
      this.spinner.hide();
      this.bsModalRef.hide();
      Swal.fire(
        'Parabéns!',
        'Usuário cadastrado com sucesso.',
        'success'
      );
    }, () => {
      this.spinner.hide();
      Swal.fire(
        'Ops!',
        'Erro ao cadastrar usuário.',
        'error'
      );
    });
  }

}
