import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import { DoctorBookService } from 'src/app/service/doctor-book.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-doctor-book-diagnostic',
  templateUrl: './doctor-book-diagnostic.component.html',
  styleUrls: ['./doctor-book-diagnostic.component.scss']
})
export class DoctorBookDiagnosticComponent implements OnInit {

  symptom: string = '';
  symptoms: any[] = [];

  exame: string = '';
  exame_value: string = '';
  examinations: any[] = [];

  result: string = '';

  basePhrase: string = 'Liste as hipóteses diagnosticas para pacientes que apresentam ';
  symtomPhrase: string = 'os seguintes sintomas: ';
  examePhase: string = 'exames com os seguintes resultados: ';

  constructor(
    private spinner: NgxSpinnerService,
    private doctorBookService: DoctorBookService
  ) { }

  ngOnInit() {
  }

  addSymptom(){
    if (this.symptom == '')
      return;

    this.symptoms.push(this.symptom);
    this.symptom = '';
  }

  addExamination(){
    if (this.exame == '' || this.exame_value == '')
      return;

    this.examinations.push({
      description: this.exame,
      value: this.exame_value
    });

    this.exame = '';
    this.exame_value = '';
  }

  removeItem(array, item) {
    const index = array.indexOf(item);
    if (index > -1) {
        array.splice(index, 1);
    }
    return array;
  }

  getDiagnostic(){    

    this.spinner.show();
    var args = this.basePhrase;

    if (this.symptoms.length > 0) {
      args = args + this.symtomPhrase;
      this.symptoms.filter(item => {
        args = args + item + ', ';
      });
      args = args.slice(0, -2);
    }

    if (this.examinations.length > 0) {
      if (this.examinations.length > 0)
        args = args + ' e ';

      args = args + this.examePhase;
      this.examinations.filter(item => {
        args = args + item.description + ': ' + item.value + ', ';
      });
      args = args.slice(0, -2);
    }
    
    this.doctorBookService.getDiagnostic(args)
    .pipe(take(1)).subscribe(response => {  
      if (response.data){
        this.result = response.data;
      }
      else {
        Swal.fire(
          'Atenção!',
          'Ocorreu um erro ao pesquisar sua resposta.',
          'warning'
        );
      }
      this.spinner.hide();
    }, err => {
        Swal.fire(
          'Ops!',
          'Ocorreu um erro ao pesquisar sua resposta.',
          'error'
        );
    });
  }

  clear(){
    this.result = '';
    this.symptoms = [];
    this.examinations = [];
  }

  mouseUp(){
    if (window.getSelection().toString())
      console.log(window.getSelection().toString());
  }

}
