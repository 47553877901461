import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-doctor-book-chat',
  templateUrl: './doctor-book-chat.component.html',
  styleUrls: ['./doctor-book-chat.component.scss']
})
export class DoctorBookChatComponent implements OnInit {

  diagnosticos: any[] = [
    { step: 1, date: '', categoryId: 1, wait: false, type: 'chat', person: 'Doctor Shift', message: 'Ok, você precisa de ajuda para fechar um diagnóstivo.' },
    { step: 2, date: '', categoryId: 1, wait: true, type: 'chat', person: 'Doctor Shift', message: 'Digite a lista de sintomas de seu paciente.' },
    { step: 3, date: '', categoryId: 1, wait: false, type: 'chat', person: 'Doctor Shift', message: 'Aguarde um momento, estamos pesquisando as doenças que se enquadram nesse quadro clínico.' }
  ];
  symptoms: string;

  exames: any[] = [];

  medicamentos: any[] = [];

  dosagem: any[] = [];

  chat: any[] = [];
  
  newMessage: string;
  currentStep: number;
  doctor: any;

  constructor() { }

  @ViewChild('scrollMessage', null) private myScrollContainer: ElementRef;

  ngOnInit() {
    this.start();

    this.doctor = {
      name: 'Vanessa de Oliveira'
    }
  }

  addNewMessage(){
    if (this.newMessage == '')
      return;

    this.chat.push(
      {
        id: 0,
        categoryId: 0,
        type: 'doctor', //doctor
        person: this.doctor.name,
        message: this.newMessage,
        date: moment()
      }
    );

    this.validateMessagem();

    this.newMessage = '';
  }

  start(){
    this.chat = [];
  }

  startFlow(step: number, messages: any[]){
    var list = messages.filter(item => { if (item.step >= step) return item });

  }

  getDiffData(date: any){
    var a = moment();
    var b = moment(date, 'minutes');
    return moment(a.diff(b)).format("m");
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  validateMessagem(){

    switch(this.currentStep) {
      case 2:
        this.symptoms = this.newMessage;
        this.startFlow(this.currentStep+1, this.diagnosticos);
        break;
      case 6:
        // code block
        break;
      default:
        // code block
    }
  }

}
